import { createAsyncThunk } from '@reduxjs/toolkit';
import NotesService from '../../services/notes.service';

export const createNotes = createAsyncThunk(
  'notes/create',
  async ({ keyNumbers, data }) => {
    const res = await NotesService.create(keyNumbers, data);
    return res.data;
  }
);

export const retrieveAllNotes = createAsyncThunk(
  'notes/retrieve',
  async ({ leadSource, key, payload }) => {
   // console.log('====', key, payload);
    const res = await NotesService.getAll(leadSource, key, payload);
    return res.data.response.items;
  }
);

export const getNotesDetails = createAsyncThunk(
  'notes/details',
  async ({ noteId }) => {
    //console.log("noteId", noteId)
    const res = await NotesService.get(noteId);
    return res.data.response.items;
  }
);

export const getNotesDetailsById = createAsyncThunk(
  'notes/detailsById',
  async ({ keyNumbers,leadSource, noteId }) => {
    //console.log("noteId", noteId)
    const res = await NotesService.getNoteById(keyNumbers, leadSource, noteId);
    return res.data.response.items;
  }
);

export const updateNote = createAsyncThunk(
  'notes/update',
  async ({ keyNumbers, noteId, data }) => {
    // console.log("body",keyNumbers,noteId, data)

    const res = await NotesService.update(keyNumbers, noteId, data);
    return res.data;
  }
);
