import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { SlArrowDown, SlArrowUp } from 'react-icons/sl';
import { RxCross2 } from 'react-icons/rx';
import RetireRejectLead from '../retireRejectLead/retireRejectLead';
import { getAPIAuthKey, getHost } from '../../common-utils/apiconfig';
// import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import * as translationUtils from '../../common-utils/translation_utils';
import { rejectLeadApi } from '../../common-utils/salesLeadsApi';

const ActionButtons = (props) => {
  const [show, setShow] = useState(false);
  const [actionBox, setActionBox] = useState(false);
  const [isRetireLead, setIsRetireLead] = useState();

  const handleCloseModal = () => {
    setShow(false);
    setIsRetireLead();
    onClickActions();
  };

  const confirmationClose = () => {
    props.setModalIsOpen(false);
  };
  const handleShow = (isShowModala, isRetire) => {
    if (props.leadRank === '' || props.leadRank === null) {
      props.setModalIsOpen(true);
      props.setAlertStatus('Failed');
      props.setAlertMessage('You must set a Rank to this lead, first!!');
      props.setIsLoading(false);
    } else {
      setShow(isShowModala);
      setIsRetireLead(isRetire);
    }
  };

  const handleRejectLeadSf = async () => {
    //console.log('lead details -', props.leadDetails?.keyNumber);
    if (props.leadRank === '' || props.leadRank === null) {
      props.setModalIsOpen(true);
      props.setAlertStatus('Failed');
      props.setAlertMessage('You must set a Rank to this lead, first!!');
      props.setIsLoading(false);
    } else {
      props.setIsLoading(true);
      const body = {
        reason: '',
        comments: '',
        rank: props.leadRank,
        rejectingEmailId: sessionStorage.getItem('oktaUserEmail'),
        rejectingUserName: sessionStorage.getItem('oktaUserName'),
      };

      const headers = {
        headers: {
          'Content-Type': 'application/json',
          'ocp-apim-subscription-key': getAPIAuthKey(),
        },
      };

      await axios
        .post(
          getHost() +
            rejectLeadApi +
            `/${props.leadDetails?.keyNumber}/SourceSystem/${props.leadSource}`,
          body,
          headers
        )
        .then((response) => {
          console.log('Reject lead', response);
          if (response?.data?.status === 1) {
            // alert('Reject lead submitted successfully!');
            props.setModalIsOpen(true);
            props.setAlertStatus('Success');
            props.setAlertMessage('Reject lead submitted successfully!');
            props.handleClose();
            handleCloseModal();
            props.setIsLoading(false);
          } else {
            props.handleCloseModal();
            props.handleClose();
            props.setIsLoading(false);
            props.setModalIsOpen(true);
            props.setAlertStatus('Failed');
            props.setAlertMessage('Reject Lead not submitted!!');
          }
        })
        .catch((error) => {
          props.setModalIsOpen(true);
          props.setAlertStatus('Failed');
          props.setAlertMessage('Something went wrong!!');
          props.setIsLoading(false);
        });
    }
  };
  const onClickActions = () => {
    setActionBox(!actionBox);
  };

  // const noteType = useSelector((state) => state.default.note.noteType);
  // const noteTitle = useSelector((state) => state.default.note.noteTitle);
  // const noteTxt = useSelector((state) => state.default.note.noteTxt);

  return (
    <>
      {/* <CustomConfirmation isOpen={props.isOpen} 
        confirmationClose={confirmationClose} 
        alertStatus={props.alertStatus}
        alertMessage={props.alertMessage}/> */}
      <div
        className={`parent_btn ${
          props.leadDetails?.status === 'RETIRED' ? 'btn_cancel_parent' : ''
        }`}>
        <div className='child_btn btn_row1'>
          <Button
            className={`transparent-button btn_cancel ${
              props.leadDetails?.status === 'RETIRED' ? 'btn_cancel_only' : ''
            }`}
            onClick={props.handleClose}>
            &lt; {translationUtils.getTranslation(547, 'Back')}
          </Button>
          {props.leadDetails?.status !== 'RETIRED' ? (
            <Button
              className='transparent-button set_btn_spnce'
              onClick={props.onClickSaveClose}>
              {translationUtils.getTranslation(3266, 'Save & Close')}
            </Button>
          ) : (
            ''
          )}
        </div>
        {props.leadDetails?.status !== 'RETIRED' ? (
          <div className='child_btn'>
            <Button
              className='transparent-button btn_act unset_btn_spnce'
              form='my-form'
              onClick={props.onClickSave}>
              {translationUtils.getTranslation(1075, 'Save')}
            </Button>

            <div className='action_container'>
              <Button
                className='transparent-button action_btn set_btn_spnce'
                onClick={onClickActions}>
                {translationUtils.getTranslation(1064, 'Actions')}
                {actionBox ? (
                  <SlArrowUp className='action_btn_arrow' />
                ) : (
                  <SlArrowDown className='action_btn_arrow' />
                )}
              </Button>
              <div
                className='action_box'
                style={{ display: actionBox ? 'block' : 'none' }}>
                <div className='actionCrossRow'>
                  <RxCross2 className='crossIcon' onClick={onClickActions} />
                </div>
                <div
                  className='action_box_row'
                  style={{
                    cursor:
                      props.leadDetails?.status === 'RETIRED'
                        ? 'unset'
                        : 'pointer',
                  }}
                  title={
                    props.leadDetails?.status === 'RETIRED'
                      ? 'Lead already retired'
                      : ''
                  }
                  onClick={() =>
                    handleShow(
                      props.leadDetails?.status === 'RETIRED' ? false : true,
                      true
                    )
                  }>
                  {translationUtils.getTranslation(3267, 'Retire')}
                </div>
                {props.leadSource === 'oec' ? (
                  <div
                    className='action_box_row'
                    style={{
                      cursor:
                        props.leadDetails?.assignmentStatusCode === 'REJECTED'
                          ? 'pointer'
                          : 'pointer',
                    }}
                    // title={
                    //   props.leadDetails?.assignmentStatusCode === "REJECTED"
                    //     ? "Lead already rejected"
                    //     : ""
                    // }
                    onClick={() =>
                      handleShow(
                        props.leadDetails?.assignmentStatusCode === 'REJECTED'
                          ? true
                          : true,
                        false
                      )
                    }>
                    {translationUtils.getTranslation(3268, 'Reject')}
                  </div>
                ) : (
                  <div
                    className='action_box_row'
                    style={{
                      cursor:
                        props.leadDetails?.assignmentStatusCode === 'REJECTED'
                          ? 'pointer'
                          : 'pointer',
                    }}
                    onClick={() => handleRejectLeadSf('SF delete')}>
                    {translationUtils.getTranslation(3268, 'Reject')}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>

      <RetireRejectLead
        show={show}
        handleCloseModal={handleCloseModal}
        isRetireLead={isRetireLead}
        leadKeyNumber={props.leadDetails?.keyNumber}
        handleClose={props.handleClose}
        setIsRetireRejectSubmited={props.setIsRetireRejectSubmited}
        setIsCommonLoading={props.setIsCommonLoading}
        setModalIsOpen={props.setModalIsOpen}
        setAlertStatus={props.setAlertStatus}
        setAlertMessage={props.setAlertMessage}
        setIsLoading={props.setIsLoading}
        leadSource={props.leadSource}
        leadRank={props.leadRank}
      />
    </>
  );
};
export default ActionButtons;
