var CLIENT_ID;

var ISSUER;
var redirectURI;
var messageURL;
var authorizeURL;
const urlParams = new URLSearchParams(window.location.search);
let cid = urlParams.get("cid");
cid = cid != undefined ? cid : "";

if (
  window.location.origin.indexOf("cor089xa270") > 0 ||
  window.location.origin.indexOf("local") > 0 ||
  window.location.origin.indexOf("salesleadsdev") > 0
) {
  if (
    window.location.origin.indexOf("cor089xa270") > 0 ||
    window.location.origin.indexOf("local") > 0
  ) {
    CLIENT_ID = "0oa1izvnlikXlhLcf0h8";
    ISSUER = "https://loginqa.parker.com";
    authorizeURL =
      cid && cid.length > 1
        ? `https://mploginqa.parker.com/?reqContext=http://localhost:3000/home/EN?cid=${cid}`
        : `https://mploginqa.parker.com/?reqContext=http://localhost:3000/home/EN`;
    // ISSUER = process.env.ISSUER || 'https://mplogindev.parker.com/?reqContext=https://salesleadsdev.us.parker.corp';
  } else {
    CLIENT_ID = process.env.CLIENT_ID || "0oa1ixmuzgy1VK40w0h8";

    ISSUER = process.env.ISSUER || "https://logintest.parker.com";

    authorizeURL =
      cid && cid.length > 1
        ? `https://mploginqa.parker.com/?reqContext=https://salesleadstest.parker.com/home/EN?cid=${cid}`
        : `https://mploginqa.parker.com/?reqContext=https://salesleadstest.parker.com/home/EN`;
  }
} else if (window.location.origin.indexOf("salesleadstest") > 0) {
  CLIENT_ID = process.env.CLIENT_ID || "0oa1izvnlikXlhLcf0h8";

  ISSUER = process.env.ISSUER || "https://loginqa.parker.com";

  authorizeURL =
    cid && cid.length > 1
      ? `https://mploginqa.parker.com/?reqContext=https://salesleadstest.parker.com/home/EN?cid=${cid}`
      : `https://mploginqa.parker.com/?reqContext=https://salesleadstest.parker.com/home/EN`;
} else if (window.location.origin.indexOf("salesleadsstage") > 0) {
  CLIENT_ID = process.env.CLIENT_ID || "0oa1izw4kh2yjwcov0h8";

  ISSUER = process.env.ISSUER || "https://loginstage.parker.com";

  authorizeURL =
    cid && cid.length > 1
      ? `https://mploginstage.parker.com/?reqContext=https://salesleadsstage.parker.com/home/EN?cid=${cid}`
      : `https://mploginstage.parker.com/?reqContext=https://salesleadsstage.parker.com/home/EN`;
} else if (window.location.origin.indexOf("salesleads") > 0) {
  CLIENT_ID = process.env.CLIENT_ID || "0oakk23u8jiTYy5ER2p7";

  ISSUER = process.env.ISSUER || "https://login.parker.com";

  authorizeURL =
    cid && cid.length > 1
      ? `https://mplogin.parker.com/?reqContext=https://salesleads.parker.com/home/EN?cid=${cid}`
      : `https://mplogin.parker.com/?reqContext=https://salesleads.parker.com/home/EN`;
}

if (
  window.location.origin.indexOf("cor089xa270") > 0 ||
  window.location.origin.indexOf("local") > 0
) {
  redirectURI = "http://localhost:3000/implicit/callback";
  messageURL = "http://localhost:3000/api/messages";
} else {
  redirectURI = `${window.location.origin}/implicit/callback`;
  messageURL = `${window.location.origin}/api/messages`;
}

const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;

// CLIENT_ID = '0oa14kyajegQqf0DV0h8';
// ISSUER =  'https://loginqa.parker.com';
// authorizeURL =
// `https://mploginqa.parker.com/?reqContext=http://localhost:3000/home/EN?cid=${cid}`;

export default {
  oidc: {
    clientId: CLIENT_ID,

    issuer: ISSUER,

    redirectUri: redirectURI,

    scopes: ["openid", "profile", "email"],

    pkce: false,

    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,

    onAuthRequired: () => {
      window.location = authorizeURL;
    },
  },

  resourceServer: {
    messagesUrl: messageURL,
  },
};
