import React, { useState, useEffect } from 'react';
import { withOktaAuth, useOktaAuth } from '@okta/okta-react';
import Header from '../header';
import { Container, Button, Tabs, Tab } from 'react-bootstrap';
import './Wrapper.css';
import OpenLeads from '../openLeads/openLeads';
import {
  callApiToFetchIP,
  callHeaderFooter_local,
  getDefaultLang,
  getOrgList,
  getVarificationUrl,
  getWcToken,
} from '../../services/varification';
import Breadcrumb from '../components/Breadcrumb';
import ActionButtons from '../components/ActionButtons';
import { getAPIAuthKey, getHost } from '../../common-utils/apiconfig';
import axios from 'axios';
//import useUnsavedChangesWarning from '../hooks/useUnsavedChangesWarning';

import { useHistory } from 'react-router-dom';
import OpenLeadsNew from '../openLeads/openLeadsNew';
import CustomConfirmation from '../components/CustomConfirmation';
//Manish - Importing the Language Translator Common API Call
import * as translationUtils from '../../common-utils/translation_utils';
import { editLeadInfo } from '../../common-utils/salesLeadsApi';
import TagManager from 'react-gtm-module';
import { useRoutes } from 'react-router';

const Wrapper = (props) => {
  const [key, setKey] = useState('open');
  const [page, setPage] = useState('open');
  const [isLoading, setIsLoading] = useState('true');
  const [isCommonLoading, setIsCommonLoading] = useState(true);

  const { oktaAuth } = useOktaAuth();
  const history = useHistory();
  const [val, setVal] = useState({});
  const [idkey, setIdKey] = useState();
  const [leadSource, setLeadSource] = useState();
  const [leadRank, setLeadRank] = useState();

  useEffect(() => {    
    
    getPreviousUrl();
    callApiToFetchIP(); 
    verification(); 

  }, []);

  const getTitleName = () => {
    switch (page) {
      case 'open':
        return 'Sales Leads';
        break;
      case 'summary':
        return 'Lead Summary Page';
        break;
      case 'notes':
        return 'Lead Notes';
        break;
      default:
        return page;
    }
  };

  const [openAction, setOpenAction] = useState(false);

  // const getLoader = (flag) => {
  //   console.log('PrintingFlag===', flag);
  //   setIsLoading({ flag });
  // };
  async function verification() {
    
    await axios(getVarificationUrl(), {
      method: 'GET',
      withCredentials: true,
    })
      .then((res) => {
        //console.log(res);
        let response = res.data;
        window.sessionStorage.setItem('oktaSessionMeId', response.id);
        window.sessionStorage.setItem('oktaLogin', response.login);
        window.sessionStorage.setItem('oktaUserEmail', response.login);
        window.sessionStorage.setItem('oktaUserName', response._links.user.name);
        window.sessionStorage.setItem('defaultLang', null);
        window.sessionStorage.setItem('oktaUserMeId', response.userId);
        
        getOrgList(history); //Codeed fto fetch the Default Customer Id so that it can be passed first time
        headerFooterCall();
      })
      .catch((err) => {
       // console.log(err);
        oktalogout();
      });
       
  }
  
      async function headerFooterCall() {      
        await getWcToken();
        // comment lang selection
        let defaultLang = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);//window.sessionStorage.getItem('defaultLang');
        let langCode= '';
        let lgCode1 ='';
        const urlParams = new URLSearchParams(window.location.search);
        const cid = urlParams.get('cid') ? urlParams.get('cid') : window.myparkerCI || '';
      //    if(defaultLang===''){
         
      //  } 
        //let defaultLang = 'en'
        if(defaultLang==='' || defaultLang===null){
          lgCode1 = await getDefaultLang();
          langCode = lgCode1.toUpperCase();
          
        }else{
          langCode =
          window.location.pathname.split('/')[2] !== '' ||
          window.location.pathname.split('/')[2] !== undefined
            ? window.location.pathname.split('/')[2].toUpperCase()
            : 'en';
        }
    let oktaSessionMeId = window.sessionStorage.getItem('oktaSessionMeId');
    var customerId = '';
    var intialOrderType = '';
    let cont_contraId = ''; 
    let org_array = [];

   
    await callHeaderFooter_local(cid, langCode, oktaSessionMeId)
      .then((res) => { 
        
       //console.log('hf_l', res);
        var temp = '';

        var attribute17 = '';
        if (res !== undefined) {          
         
          //Codeed fto fetch the Default Customer Id so that it can be passed first time
          window.$('header#header').append(res['header-html']);
          window.$('footer#footer').append(res['footer-html']);
          temp = res['header-html'].split('myparkerCustomerId');
          customerId = temp[1]
            ?.substring(0, temp[1]?.indexOf('</script>'))
            .replace('=', '')
            .replace(/"/g, '')
            .trim();
          sessionStorage.setItem('customerId', customerId);

          //Updating State for Cid so tat it can be dynamcialy passed to Leads API
          // props.handleUpdatedCid(customerId);
          //Manish - Setting the Transation Land Code based on the Location we received as defeult from the HEader Footer

          translationUtils.setTranslations(this, langCode);

          //Ended- manish
          attribute17 = window.$('#locale-business').text().split(' ').slice();
          var cloneArray = attribute17?.slice();
          sessionStorage.setItem(
            'attribute17',
            cloneArray?.slice(1, cloneArray.length).join(' ')
          );
          intialOrderType =
            window.location.pathname.split('/')[4] !== '' ||
            window.location.pathname.split('/')[4] !== undefined
              ? window.location.pathname.split('/')[4]
              : 'open';
          handleLoader(false); // Making the Loader False in only case when we get the response
          // this.props.history.push({
          //   pathname: this.props.history.location.pathname,   search: '?cid=' + customerId, });
        }else{
           handleLoader(false);
           getOrgList(history); //Codeed fto fetch the Default Customer Id so that it can be passed first time
          //  let cId= window.sessionStorage.getItem('defaultCid');
          //  sessionStorage.setItem('customerId', cId);
        }
        //props.setCustomerId(customerId);
        //props.handleLoader(false);
      })
      .catch((err) => {
        handleLoader(false); // Making the Loader False in only case when we get the response
        // console.log(err);
        // oktalogout();
      });

      TagManager.dataLayer({
        dataLayer: {
          event: 'page_view',
          page_referrer: decodeURIComponent(previousURL),
          content_group: 'myparker: Sales Leads',
          country: 'N/A',
          language: 
          window.location.pathname.split('/')[2] !== '' ||
          window.location.pathname.split('/')[2] !== undefined
            ? window.location.pathname.split('/')[2].toUpperCase()
            : 'en',
          content_owner: '687PDC',
          nav_owner: '687PDC',
          product_category_name: 'N/A',
          product_series_name: 'N/A',
          product_series_id: 'N/A',
          product_id: 'N/A',
          part_name: 'N/A',
          is_category_page: 'No',
          is_series_page: 'No',
          is_pdp: 'No',
          is_req_quote: 'N/A',
          is_wtb: 'N/A',
          is_buyable_online: 'N/A',
          is_configurable: 'N/A',
          user_ip: window.sessionStorage.getItem('clientIp'),
          user_id: window.sessionStorage.getItem('userId'),
          user_authenticated: 'authenticated user - Myparker',
          user_company_name: window.sessionStorage.getItem('attribute17'),
          user_company_id: window.sessionStorage.getItem('customerId'),
          
        },
      });


      
  }
  const urlParams = new URLSearchParams(window.location.search); 
  // const router = useRoutes();
  // const param = router.query; 
//  console.log('urlParams', urlParams);
      var previousURL = getCookie('previousURL');
    function getCookie(name) {
      var cookies = document.cookie.split('; ');
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].split('=');
        if (cookie[0] === name) {
          return cookie[1];
        }
      }
      return 'N/A';
    }

   function getPreviousUrl(){
    if (decodeURIComponent(previousURL) == window.location.href) {
      previousURL = getCookie('previousURL1');
    }
    document.cookie =    'previousURL=' +   encodeURIComponent(window.location.href) +'; domain=parker.com; path=/';
   }
 

  const [allCount, setallCount] = useState(0);
  const [allOpenCount, setallOpenCount] = useState(0);
  const [retireCount, setretireCount] = useState(0);
  const [wonCount, setwonCount] = useState(0);
  const [keyNumber, setKeyNumber] = useState('');

  const handleClose = () => {
    setPage('open');
    setOpenAction(false);
  };

  const handleLoader = (flagg) => {
    setIsLoading(flagg);
  };

  const [leadDetails, setLeadDetails] = useState({});
  const [isRetireRejectSubmited, setIsRetireRejectSubmited] = useState('');
  const [updatedDealSize, setUpdatedDealSize] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [alertStatus, setAlertStatus] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const onClickSave = async (event) => {
    if (leadRank === '' || leadRank === null) {
      setModalIsOpen(true);
      setAlertStatus('Failed');
      setAlertMessage('You must set a Rank to this lead, first!!');
    } else {
    setIsCommonLoading(true);
    if (typeof val !== 'undefined') {
    }
    const url = getHost() + editLeadInfo + idkey+`/SourceSystem/`+leadSource;
    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        dataType: 'json',
        'ocp-apim-subscription-key': getAPIAuthKey(),
      },
      data: val,
      url,
    };
    await axios(options)
      .then((res) => {
        //  alert('Lead update successfully.');
        setModalIsOpen(true);
        setAlertStatus('Success');
        setAlertMessage('Lead data updated successfully!!');
        // <CustomConfirmation/>
        setIsCommonLoading(false);
        res.data.response.dealSize === 0
          ? setUpdatedDealSize('')
          : setUpdatedDealSize(res.data.response.dealSize);
        /*setPage('open'); */
        //console.log('yeh we have', res);
      })
      .catch((er) => {
        setIsCommonLoading(false);
        setModalIsOpen(true);
        setAlertStatus('Failed');
        setAlertMessage('Something Went Wrong!!');
      });
   }
  };
  const onClickSaveClose = async (event) => {
    if (leadRank === '' || leadRank === null) {
      setModalIsOpen(true);
      setAlertStatus('Failed');
      setAlertMessage('You must set a Rank to this lead, first!!');
    } else {
    setIsLoading(true);
    if (typeof val !== 'undefined') {
      //console.log('obj====', val, idkey);
    }
    const url =
      getHost() + editLeadInfo + idkey+`/SourceSystem/`+leadSource;
    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        dataType: 'json',
        'ocp-apim-subscription-key': getAPIAuthKey(),
      },
      data: val,
      url,
    };
    await axios(options)
      .then((res) => {
        //alert('Lead updated successfully.');
        setModalIsOpen(true);
        setAlertStatus('Success');
        setAlertMessage('Lead data updated successfully!!');
        setIsLoading(false);
        setPage('open');
        setOpenAction(false);
        //console.log('yeh we have save and close', res);
      })
      .catch((er) => {
        //console.log('no data sorry ', er);
        setIsLoading(false);
      });
     }
  };
  const oktalogout = async () => {
    window.sessionStorage.clear();
    await oktaAuth.tokenManager.clear();
    await oktaAuth.signOut('/logout');
  };
  const confirmationClose = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <CustomConfirmation
        isOpen={modalIsOpen}
        confirmationClose={confirmationClose}
        alertStatus={alertStatus}
        alertMessage={alertMessage}
      />
      {isLoading || isCommonLoading ? (
        <div id="preloader" className="order-status-hifi">
          <div className="loading-overlay" style={{ position: 'fixed' }}>
            &nbsp; <p></p>
          </div>
        </div>
      ) : (
        ''
      )}
      <Header />
      <Breadcrumb pageName={getTitleName()} />
      <div className="container-fluid  reduce-padding">
        <div className="oecWrapper">
          <Container className="salesLeadWrapper">
            <div className="salesLeadHeading">
              {/* <div>
                <h2 className="no-margin"> {getTitleName()}</h2>
              </div> */}
              <div className="headingRight">
                {openAction ? (
                  <ActionButtons
                    leadDetails={leadDetails}
                    handleClose={handleClose}
                    setIsRetireRejectSubmited={setIsRetireRejectSubmited}
                    onClickSave={onClickSave}
                    onClickSaveClose={onClickSaveClose}
                    setIsCommonLoading={setIsCommonLoading}
                    setAlertStatus={setAlertStatus}
                    setAlertMessage={setAlertMessage}
                    setModalIsOpen={setModalIsOpen}
                    setIsLoading={setIsLoading}
                    leadSource={leadSource}
                    setLeadRank={setLeadRank}
                    leadRank={leadRank}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
            {!isLoading ? (
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => {
                  setKey(k);
                  //console.log(key,';;',k)

                  if (k !== key) {
                    //handleClose();
                    setPage('');
                  } else {
                    setPage('open');
                  }
                  handleClose();
                  // setDirty();
                }}
                className="tab-heads"
              >
                <Tab
                  eventKey="open"
                  title={`${
                    translationUtils.getTranslation(3255, 'All Open Leads') +
                    ' (' +
                    allOpenCount
                  })`}
                >
                  <OpenLeadsNew
                    setPage={setPage}
                    setallOpenCount={setallOpenCount}
                    page={page}
                    setOpenAction={setOpenAction}
                    setLeadDetails={setLeadDetails}
                    dataS={'openleads'}
                    isRetireRejectSubmited={isRetireRejectSubmited}
                    onClickSave={onClickSave}
                    setVal={setVal}
                    setIdKey={setIdKey}
                    handleClose={handleClose}
                    setIsCommonLoading={setIsCommonLoading}
                    updatedDealSize={updatedDealSize}
                    setUpdatedDealSize={setUpdatedDealSize}
                    setAlertStatus={setAlertStatus}
                    setAlertMessage={setAlertMessage}
                    setModalIsOpen={setModalIsOpen}
                    setLeadSource={setLeadSource}
                    leadSource={leadSource}
                    setLeadRank={setLeadRank}
                    leadRank={leadRank}
                  />
                </Tab>
                <Tab
                  eventKey="all"
                  title={`${
                    translationUtils.getTranslation(3256, 'All Leads') +
                    ' (' +
                    allCount
                  })`}
                >
                  <OpenLeadsNew
                    dataS={'allleads'}
                    setallCount={setallCount}
                    setPage={setPage}
                    page={page}
                    setOpenAction={setOpenAction}
                    setLeadDetails={setLeadDetails}
                    isRetireRejectSubmited={isRetireRejectSubmited}
                    onClickSave={onClickSave}
                    setVal={setVal}
                    setIdKey={setIdKey}
                    handleClose={handleClose}
                    setIsCommonLoading={setIsCommonLoading}
                    updatedDealSize={updatedDealSize}
                    setUpdatedDealSize={setUpdatedDealSize}
                    setAlertStatus={setAlertStatus}
                    setAlertMessage={setAlertMessage}
                    setModalIsOpen={setModalIsOpen}
                    setLeadSource={setLeadSource}
                    leadSource={leadSource}
                    setLeadRank={setLeadRank}
                    leadRank={leadRank}
                  />
                </Tab>
                <Tab
                  eventKey="retired"
                  title={`${
                    translationUtils.getTranslation(3257, 'All Leads Retired') +
                    ' (' +
                    retireCount
                  })`}
                >
                  <OpenLeadsNew
                    setPage={setPage}
                    page={page}
                    setOpenAction={setOpenAction}
                    setLeadDetails={setLeadDetails}
                    setretireCount={setretireCount}
                    dataS={'RETIRED'}
                    noteType={'GENERAL'}
                    isRetireRejectSubmited={isRetireRejectSubmited}
                    onClickSave={onClickSave}
                    setVal={setVal}
                    setIdKey={setIdKey}
                    handleClose={handleClose}
                    setIsCommonLoading={setIsCommonLoading}
                    updatedDealSize={updatedDealSize}
                    setAlertStatus={setAlertStatus}
                    setAlertMessage={setAlertMessage}
                    setModalIsOpen={setModalIsOpen}
                    setLeadSource={setLeadSource}
                    leadSource={leadSource}
                    setLeadRank={setLeadRank}
                    leadRank={leadRank}
                  />
                </Tab> 
                <Tab
                  eventKey="won"
                  title={`${
                    translationUtils.getTranslation(3258, 'All Leads Won') +
                    ' (' +
                    wonCount
                  })`}
                >
                  <OpenLeadsNew
                    setPage={setPage}
                    page={page}
                    setOpenAction={setOpenAction}
                    setLeadDetails={setLeadDetails}
                    setwonCount={setwonCount}
                    dataS={'WON'}
                    isRetireRejectSubmited={isRetireRejectSubmited}
                    onClickSave={onClickSave}
                    setVal={setVal}
                    setIdKey={setIdKey}
                    handleClose={handleClose}
                    setIsCommonLoading={setIsCommonLoading}
                    updatedDealSize={updatedDealSize}
                    setUpdatedDealSize={setUpdatedDealSize}
                    setAlertStatus={setAlertStatus}
                    setAlertMessage={setAlertMessage}
                    setModalIsOpen={setModalIsOpen}
                    setLeadSource={setLeadSource}
                    leadSource={leadSource}
                    setLeadRank={setLeadRank}
                    leadRank={leadRank}
                  />
                </Tab>
              </Tabs>
            ) : (
              ''
            )}
          </Container>
        </div>
      </div>
    </>
  );
};
export default withOktaAuth(Wrapper);
