import React, { useState, useEffect } from 'react';
//import Col from "react-bootstrap/Col";
import Nav from 'react-bootstrap/Nav';
//import Row from "react-bootstrap/Row";
import Tab from 'react-bootstrap/Tab';
//import Form from "react-bootstrap/Form";
import './detailPage.css';
import Notes from './notes/notes';
import { Row, Col, Form, } from 'react-bootstrap';
import axios from 'axios';
import { getAPIAuthKey, getHost } from '../../../common-utils/apiconfig'; 
import CustomSelect from '../../components/CustomSelect';
import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux'
import useUnsavedChangesWarning from '../../hooks/useUnsavedChangesWarning';
import * as translationUtils from '../../../common-utils/translation_utils';
import { Tooltip } from '@mui/material';
import { getLeadData } from '../../../common-utils/salesLeadsApi';

const DetailPage = ({
  setPage,
  dataS,
  keyNumbers,
  onClickSave,
  setVal,
  setIdKey,
  setOpenAction,
  setIsCommonLoading,
  setRefreshList,
  setNoteLoading,
  noteloading,
  updatedDealSize,
  setUpdatedDealSize,
  setAlertStatus,
  setAlertMessage,
  setModalIsOpen,
  // noteType,
  setDistAccountNumber,
  leadSource,
  setLeadRank,
  leadRank,
}) => {
  //const [checked, setChecked] = useState(false);
  const [statusValue, setStatusValue] = useState('UNQUALIFIED');
  const changeScreen = (name) => {
    setPage(name);
    name === 'notes' ? setOpenAction(false) : setOpenAction(true);
  };
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();


  const [noteCount, setNoteCount] = useState('');
  const [rowData, setRowData] = useState({
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    assignmentStatusCode: '',
    cityOrTown: '',
    contactEmail: '',
    country: '',
    currency: '',
    dealSize: '',
    description: '',
    distributor: '',
    distributorAccountNumber: '',
    distributorEmail: '',
    existingAccount: '',
    existingAccountNumber: '',
    existingContact: '',
    jobTitle: '',
    keyNumber: '',
    lastUpdateDate: '',
    lastUpdatedBy: '',
    leadId: '',
    leadName: '',
    owner: '',
    postalCode: '',
    products: [],
    rank: '',
    source: '',
    status: '',
    workPhoneNumber: '',
  });
  const [loading, setLoading] = useState(true);
  const [rankValue, setRankValue] = useState('');
  useEffect(() => {
    // console.log("keyNumbersDetai", keyNumbers);
    let _isMounted = true;

    // .get(getHost() + `LeadsApi/api/Leads/GetLeadsWithRequiredFields/LeadNumber/${keyNumbers}/true`)
    if (typeof keyNumbers !== 'undefined') {
      axios(
        getHost() + getLeadData + keyNumbers+`/SourceSystem/${leadSource}/true`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'ocp-apim-subscription-key': getAPIAuthKey(),
          },
        }
      )
        .then((res) => {
          if (_isMounted && res.status === 200) {
           //  console.log("lead details", res?.data?.response?.items[0]?.distributorAccountNumber);
            setDistAccountNumber(res?.data?.response?.items[0]?.distributorAccountNumber)
            setRowData(res?.data?.response?.items[0]);
            setStatusValue(res?.data?.response?.items[0]?.status);
            setRankValue(res?.data?.response?.items[0]?.rank);
            //setLeadRank('');
            setLeadRank(res?.data?.response?.items[0]?.rank);
            setLoading(false);
            setUpdatedDealSize('');
          }
        })
        .catch((err) => console.log(err));

      return () => {
        _isMounted = false;
        setLoading(true);
      };
    }
  }, []);

  useEffect(() => {
    // console.log('rowData in detail --', rowData);
    let product =
      rowData?.products && rowData?.products.length > 0
        ? rowData?.products.map((item) => ({
            leadProductId: Number(item.leadProductId),
            quantity: Number(item.quantity),
            estimatedUnitPrice: Number(item.estimatedPrice),
            part_c: item.part,
            comments_c: item.comments,
          }))
        : [];

    let Payload = {
      statusCode: rowData?.status,
      rank: rowData?.rank,
      retireComment: rowData?.retireComment,
      distributorEmail_c: rowData?.distributorEmail,
      lastUpdatedBy: rowData?.lastUpdatedBy,
      mklProdAssoc1: product,
      dealSize: Number(rowData?.dealSize),
    };
     //console.log('Payload=====', rowData);
    //Closed ----console.log(JSON.parse(JSON.stringify(Payload)), Payload);
    setIdKey(rowData?.keyNumber);
    setVal(Payload);
  }, [rowData]);

  const notesSlice = useSelector((state) => state.notesSlice);
  
  useEffect(() => {
    setNoteCount(notesSlice.length);
  }, [notesSlice]);

  const inputChangedHandler = (event) => {
    const updatedKeyword = event.target.value;
    // May be call for search result

    setRowData({ ...rowData, [event.target.name]: event.target.value });
    setDirty();
    //console.log("updatedKeyword", event, [event.target.name],  event.target.value, rowData)
  };
  const handleChange = (event) => {
    setLeadRank(event.value);
    setRankValue(event.value);
    setRowData({ ...rowData, ['rank']: event.value });
  };

  const statusChangeHandler = (e) => {
    e.preventDefault();
    //console.log(e, e.target.value)
    setStatusValue(e.target.value);

    setRowData({ ...rowData, [e.target.name]: e.target.value });
  };

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const onQuantityChange = (i, e) => {
    let new_updated_data = rowData?.products.map((employee, index) => {
      if (index === i) {
        return {
          ...employee,
          quantity: e.target.value,
          amount: e.target.value * employee.estimatedPrice,
        };
      }
      return employee;
    });

    setRowData({ ...rowData, products: [...new_updated_data] });
  };
  const onAmountChange = (i, e) => {
    let new_updated_data = rowData?.products.map((employee, index) => {
      if (index === i) {
        return {
          ...employee,
          estimatedPrice: e.target.value,
          amount: e.target.value * employee.quantity,
        };
      }
      return employee;
    });

    setRowData({ ...rowData, products: [...new_updated_data] });
  };
  const onNameChange = (i, e) => {
    let new_updated_data = rowData?.products.map((employee, index) => {
      if (index === i) {
        return {
          ...employee,
          name: e.target.value,
        };
      }
      return employee;
    });

    setRowData({ ...rowData, products: [...new_updated_data] });
  };
  const onCommentsChange = (i, e) => {
    let new_updated_data = rowData?.products.map((employee, index) => {
      if (index === i) {
        return {
          ...employee,
          comments: e.target.value,
        };
      }
      return employee;
    });

    setRowData({ ...rowData, products: [...new_updated_data] });
  };
  const onPartChange = (i, e) => {
    let new_updated_data = rowData?.products.map((employee, index) => {
      if (index === i) {
        return {
          ...employee,
          part: e.target.value,
        };
      }
      return employee;
    });

    setRowData({ ...rowData, products: [...new_updated_data] });
  };

  const getFormattedDate = (newDate) => {
    let objectDate = new Date(newDate);
    let day = objectDate.getDate();
    let month = monthNames[objectDate.getMonth()];
    let year = objectDate.getFullYear();
    let format1 = month + '/' + day + '/' + year;
    return format1;
  };

  const columns = [
    {
      name: translationUtils.getTranslation(355, 'Name'),
      grow: 1.7,
      sortable: true,
      selector: (row, index) => row.name,
    },
    {
      name: translationUtils.getTranslation(88, 'Quantity'),
      grow: 1,
      cell: (row, index) => (
        <input
          type="number"
          onKeyDown={(event) => {
            let regex = /^[0-9.]+$/;
            var theEvent = event || window.event;
            var key = theEvent.keyNumber || theEvent.which;

            if (
              key === 9 ||
              key === 37 ||
              key === 39 ||
              key === 8 ||
              key === 46
            ) {
              return;
            } else {
              if (!regex.test(event.key)) {
                event.preventDefault();
              }
            }
          }}
          onChange={(e) => onQuantityChange(index, e)}
          // onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          className="fw-400 m-0 text-right"
          defaultValue={row.quantity}
          readOnly={statusValue === 'RETIRED'}
        />
      ),
      sortable: true,
    },
    {
      name: translationUtils.getTranslation(3291, 'Estimated Price'),
      sortable: true,
      grow: 1.3,
      cell: (row, index) => (
        <input
          type="number"
          onKeyDown={(event) => {
            let regex = /^[0-9.]+$/;
            var theEvent = event || window.event;
            var key = theEvent.keyNumber || theEvent.which;

            if (
              key === 9 ||
              key === 37 ||
              key === 39 ||
              key === 8 ||
              key === 46
            ) {
              return;
            } else {
              if (!regex.test(event.key)) {
                event.preventDefault();
              }
            }
          }}
          onChange={(e) => onAmountChange(index, e)}
          className="fw-400 m-0 text-right"
          defaultValue={row.estimatedPrice}
          readOnly={statusValue === 'RETIRED'}
        />
      ),
    },
    {
      name: translationUtils.getTranslation(3082, 'Amount'),
      grow: 1,
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: translationUtils.getTranslation(2049, 'Group'),
      grow: 1,
      selector: (row) => row.product || 'N/A',
      sortable: true,
    },
    {
      name: translationUtils.getTranslation(72, 'Division'),
      grow: 1,
      sortable: true,
      selector: (row) => row.division,
    },
    {
      name: translationUtils.getTranslation(1565, 'Part'),
      grow: 1,
      sortable: true,
      cell: (row, index) => (
        <input
          type="text"
          className="fw-400 m-0"
          defaultValue={row.part}
          onChange={(e) => onPartChange(index, e)}
          readOnly={statusValue === 'RETIRED'}
        />
      ),
    },
    {
      name: translationUtils.getTranslation(751, 'Comments'),
      sortable: true,
      grow: 1,
      cell: (row, index) => (
        <textarea
          type="text"
          className="fw-400 m-0 tx"
          rows="2"
          defaultValue={row.comments || ''}
          onChange={(e) => onCommentsChange(index, e)}
          readOnly={statusValue === 'RETIRED'}
        />
      ),
    },
  ];
  const customStyles = {
    tableWrapper: {
      style: {
        border: '#e2e5e8 solid 1px',
      },
    },

    headRow: {
      style: {
        padding: '7px 3px',
        fontWeight: 'bold',
        color: '#000000',
        minHeight: 'unset',
        backgroundColor: '#FAF9F9',
      },
    },
    rows: {
      style: {
        minHeight: '40px',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        backgroundColor: '#faf9f9',
        fontWeight: 500,
      },
    },
    cells: {
      style: {
        padding: '16px 6px !important',
        fontWeight: 500,
        div: {
          whiteSpace: 'nowrap !important',
          overFlow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
  };
  // console.log("Note List ---- ", notesSlice.length)
  return (
    <>
      {Prompt}
      <div className="summ">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col lg={3} className="sideNav">
              <Nav variant="pills" className="">
                <Nav.Item
                  onClick={() => changeScreen('summary')}
                  className="summaryTab"
                >
                  <Nav.Link eventKey="first">
                    {/* <canvas className="canvas"></canvas> */}
                    <img className="canvasIcon"
                        alt="Failed"
                        src="/assets/img/summary.svg"
                        width={25}
                        height={25}
                      />
                    <span className="pipe"></span>
                    {translationUtils.getTranslation(3265, 'Summary')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  onClick={() => changeScreen('notes')}
                  className="noteTab"
                >
                  
                    <Nav.Link eventKey="second" className="CamelC">
                      {/* <canvas className="canvas"></canvas> */}
                      <img className="canvasIcon"
                        alt="Failed"
                        src="/assets/img/notes.svg"
                        width={25}
                        height={25}
                      />
                      <span className="pipe"></span>
                      <Tooltip title={translationUtils.getTranslation(3328, 'Add follow up notes here. It will be visible in Parker system.')} 
                      arrow placement='right'>
                        <span>  
                          {translationUtils.getTranslation(1809, 'Notes')}&nbsp;{noteloading===false? ('('+noteCount+')'):''}
                        </span>
                      </Tooltip>
                    </Nav.Link> 
                </Nav.Item>
                
              </Nav>
            </Col>
            <Col lg={9} className="detailCol">
              <div className="detailContainer">
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    {!loading ? (
                      <>
                        <Form id="my-form" onSubmit={onClickSave}>
                          {/* <Col sm={6} className="head-button-wrapper">
                          <Button className="transparent-button" onClick={onClickSave} >Submit</Button>
                        </Col> */}
                          <input
                            type="hidden"
                            name="products"
                            value={
                              rowData &&
                              rowData.products &&
                              rowData.products.length > 0
                                ? JSON.stringify(rowData.products)
                                : []
                            }
                          />

                          <Row sm={2}>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  3259,
                                  'Lead Number'
                                )}
                              </label>
                              <p className="ptext">
                                {' '}
                                {rowData?.keyNumber ? rowData?.keyNumber : ''}
                              </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  3270,
                                  'Deal Size'
                                )}
                              </label>
                              {rowData?.products.length === 0 ? (
                                <input
                                  type="number"
                                  onKeyDown={(event) => {
                                    let regex = /^[0-9.]+$/;
                                    var theEvent = event || window.event;
                                    var key =
                                      theEvent.keyNumber || theEvent.which;

                                    if (
                                      key === 9 ||
                                      key === 37 ||
                                      key === 39 ||
                                      key === 8 ||
                                      key === 46
                                    ) {
                                      return;
                                    } else {
                                      if (!regex.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }
                                  }}
                                  onChange={(event) =>
                                    inputChangedHandler(event)
                                  }
                                  className="fw-400"
                                  name="dealSize"
                                  placeholder={translationUtils.getTranslation(
                                    3270,
                                    'Deal Size'
                                  )}
                                  readOnly={statusValue === 'RETIRED'}
                                  defaultValue={
                                    updatedDealSize !== ''
                                      ? updatedDealSize
                                      : rowData?.dealSize
                                      ? rowData?.dealSize
                                      : ''
                                  }
                                />
                              ) : (
                                <p className="ptext">
                                  {updatedDealSize !== ''
                                    ? updatedDealSize
                                    : rowData?.dealSize}
                                </p>
                              )}
                            </div>
                          </Row>
                          <Row sm={2}>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  3271,
                                  'Lead Name'
                                )}
                              </label>
                              <p className="ptext">
                                {rowData?.leadName ? rowData?.leadName : ''}
                              </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  3286,
                                  'Currency'
                                )}
                              </label>
                              <p className="ptext">
                                {rowData?.currency ? rowData?.currency : ''}
                              </p>
                            </div>
                          </Row>
                          <Row sm={2}>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  648,
                                  'Contact'
                                )}
                              </label>
                              <p className="ptext">
                                {rowData?.existingContact}
                              </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                            <Tooltip title={translationUtils.getTranslation(
                              3326, 'Parker sales contact')} arrow placement='top'>
                              <label className="sl-Label">
                                {translationUtils.getTranslation(3272, 'Owner')}
                              </label>
                              </Tooltip>
                              
                              <p className="ptext">
                                <Tooltip title={translationUtils.getTranslation(
                                3326, 'Parker sales contact')} arrow placement='top'>
                                  <span> {rowData?.owner ? rowData?.owner : ''}</span>
                                </Tooltip>
                              </p>
                              
                            </div>
                          </Row>
                          <Row sm={2}>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  1750,
                                  'Job Title'
                                )}
                              </label>
                              <p className="ptext">
                                {rowData?.jobTitle ? rowData?.jobTitle : ''}
                              </p>
                            </div>
                            {rowData?.status === 'RETIRED' ? (
                              <div className="col-lg-6 col-md-6 col-sm-6">
                                <label className="sl-Label">
                                  {' '}
                                  {translationUtils.getTranslation(
                                    1842,
                                    'Status'
                                  )}
                                </label>
                                <input
                                  type="text"
                                  className="fw-400"
                                  name="status"
                                  value={statusValue}
                                  readOnly
                                />
                              </div>
                            ) : (
                              <div className="col-lg-6 col-md-6 col-sm-6">
                                <label className="sl-Label">
                                  {translationUtils.getTranslation(
                                    1842,
                                    'Status'
                                  )}
                                </label>
                                <br />
                                <div className="switch-field ">
                                  <input
                                    type="radio"
                                    className={`btn-status btn-status-unqualified${
                                      statusValue === 'UNQUALIFIED'
                                        ? ' btn-status-toggle'
                                        : ''
                                    }`}
                                    onChange={(event) =>
                                      statusChangeHandler(event)
                                    }
                                    placeholder={translationUtils.getTranslation(
                                      1842,
                                      'Status'
                                    )}
                                    name="status"
                                    id={`radio-one-${keyNumbers}-${dataS}`}
                                    value="UNQUALIFIED"
                                    checked={
                                      statusValue === 'UNQUALIFIED'
                                        ? true
                                        : false
                                    }
                                  />
                                  <label
                                    htmlFor={`radio-one-${keyNumbers}-${dataS}`}
                                  >
                                    {' '}
                                    {translationUtils.getTranslation(
                                      3274,
                                      'Unqualified'
                                    )}
                                  </label>

                                  <input
                                    type="radio"
                                    className={`btn-status btn-status-unqualified ${
                                      statusValue === 'QUALIFIED'
                                        ? ' btn-status-toggle'
                                        : ''
                                    }`}
                                    onChange={(event) =>
                                      statusChangeHandler(event)
                                    }
                                    placeholder="Status"
                                    name="status"
                                    //id="radio-two"
                                    id={`radio-two-${keyNumbers}-${dataS}`}
                                    value="QUALIFIED"
                                    checked={
                                      statusValue === 'QUALIFIED' ? true : false
                                    }
                                  />
                                  <label
                                    htmlFor={`radio-two-${keyNumbers}-${dataS}`}
                                  >
                                    {translationUtils.getTranslation(
                                      3273,
                                      'Qualified'
                                    )}
                                    {''}
                                  </label>
                                </div>
                              </div>
                            )}
                            {statusValue === 'RETIRED' ? (
                              <div className="col-lg-6 col-md-6 col-sm-6">
                                <label className="sl-Label">
                                  {translationUtils.getTranslation(
                                    3260,
                                    'Retired Reason'
                                  )}
                                </label>
                                <p className="ptext">
                                  {rowData?.retireReasonCode
                                    ? rowData?.retireReasonCode
                                    : ''}
                                </p>
                              </div>
                            ) : (
                              ''
                            )}

                            {statusValue === 'RETIRED' ? (
                              <div className="col-lg-6 col-md-6 col-sm-6">
                                <label className="sl-Label">
                                  {translationUtils.getTranslation(
                                    3321,
                                    'Retire Comment'
                                  )}
                                </label>
                                <p className="ptext">
                                  {rowData?.retireComment
                                    ? rowData?.retireComment
                                    : ''}
                                </p>
                              </div>
                            ) : (
                              ''
                            )}
                          </Row>
                          <Row sm={2}>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                            {/* <OverlayTrigger
                              delay={{ hide: 450, show: 300 }}
                              overlay={(props) => (
                                <Tooltip {...props}>
                                  Hii, I am a simple tooltip information!!!
                                </Tooltip>
                              )}
                              placement="bottom"
                            > */}
                            <Tooltip title={translationUtils.getTranslation(
                              3324, 'Account name in Parker systems')} arrow placement='top'>
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  3279,
                                  'Account'
                                )}
                              </label>
                              </Tooltip>
                              {/* </OverlayTrigger> */}
                                <p className="ptext">
                                <Tooltip title={translationUtils.getTranslation(
                              3324, 'Account name in Parker systems')} arrow placement='top'>
                                <span> {rowData?.existingAccount}</span>
                                </Tooltip>
                                 
                                </p> 
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <label className="sl-Label">
                                {translationUtils.getTranslation(3275, 'Rank')}
                              </label>
                              {leadSource==='oec'?(
                                <CustomSelect
                                placeholder={
                                  translationUtils.getTranslation(
                                    1207,
                                    'Select an option'
                                  ) + '....'
                                }
                                onChange={(event) => handleChange(event)}
                                defaultValue={rankValue}
                                value={rankValue}
                                name="rank"
                                options={[
                                  {
                                    label: translationUtils.getTranslation(
                                      3276,
                                      'Hot'
                                    ),
                                    value: 'HOT',
                                  },
                                  {
                                    label: translationUtils.getTranslation(
                                      3277,
                                      'Warm'
                                    ),
                                    value: 'WARM',
                                  },
                                  {
                                    label: translationUtils.getTranslation(
                                      3278,
                                      'Cold'
                                    ),
                                    value: 'COOL',
                                  },
                                ]}
                                isDisabled={statusValue === 'RETIRED'}
                              />
                              ):(<CustomSelect
                                placeholder={
                                  translationUtils.getTranslation(
                                    1207,
                                    'Select an option'
                                  ) + '....'
                                }
                                onChange={(event) => handleChange(event)}
                                defaultValue={rankValue}
                                value={rankValue}
                                name="rank"
                                options={[
                                  {
                                    label: translationUtils.getTranslation(
                                      3276,
                                      'Hot'
                                    ),
                                    value: 'Hot',
                                  },
                                  {
                                    label: translationUtils.getTranslation(
                                      3277,
                                      'Warm'
                                    ),
                                    value: 'Warm',
                                  },
                                  {
                                    label: translationUtils.getTranslation(
                                      3278,
                                      'Cold'
                                    ),
                                    value: 'Cold',
                                  },
                                ]}
                                isDisabled={statusValue === 'RETIRED'}
                              />)}
                              
                            </div>
                          </Row>
                          <Row sm={2}>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                            <Tooltip title={translationUtils.getTranslation(
                              3325, 'Account number in Parker systems')} arrow placement='top'>
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  3279,
                                  'Account'
                                )}{' '}
                                #
                              </label>
                              </Tooltip>
                              
                              <p className="ptext">
                                <Tooltip title={translationUtils.getTranslation(
                                3325, 'Account number in Parker systems')} arrow placement='top'>
                                  <span>{rowData?.existingAccountNumber}</span>
                                  </Tooltip>
                              </p>
                              
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  3322,
                                  'Last Updated Date'
                                )}
                              </label>
                              <p className="ptext">
                                {rowData?.lastUpdateDate
                                  ? getFormattedDate(rowData?.lastUpdateDate)
                                  : ''}
                              </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  3281,
                                  'Contact Email'
                                )}
                              </label>
                              <p className="ptext">
                                {rowData?.contactEmail
                                  ? rowData?.contactEmail
                                  : ''}
                              </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                            <Tooltip title={translationUtils.getTranslation(
                              3327, 'Distributor account name in Parker systems')} arrow placement='top'>
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  3282,
                                  'Distributor'
                                )}
                              </label>
                              </Tooltip>
                              <p className="ptext">
                                <Tooltip title={translationUtils.getTranslation(
                                3327, 'Distributor account name in Parker systems')} arrow placement='top'>
                                  <span>
                                    {rowData?.distributor
                                    ? rowData?.distributor
                                    : ''}
                                  </span>
                                </Tooltip>
                              </p>

                            </div>
                          </Row>
                          <Row sm={2}>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  3283,
                                  'Work Phone Number'
                                )}
                              </label>
                              <p className="ptext">
                                {rowData?.workPhoneNumber
                                  ? rowData?.workPhoneNumber
                                  : ''}
                              </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                            <Tooltip title={translationUtils.getTranslation(
                              3329, 'Distributor account number in Parker systems')} arrow placement='top'>
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  3284,
                                  'Distributor Account'
                                )}
                              </label>
                              </Tooltip>
                              <p className="ptext">
                                <Tooltip title={translationUtils.getTranslation(
                                3329, 'Distributor account number in Parker systems')} arrow placement='top'>
                                  <span>
                                    {rowData?.distributorAccountNumber
                                    ? rowData?.distributorAccountNumber
                                    : ''}
                                  </span>
                                </Tooltip> 
                               </p>
                            </div>
                          </Row>
                          <Row sm={2}>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  102,
                                  'Description'
                                )}
                              </label>
                              <p className="ptext">
                                {rowData?.description || ''}
                              </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <Tooltip title={translationUtils.getTranslation(
                                3330,'Distributor email for lead notification')} arrow placement='top'>
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  3285,
                                  'Distributor Email'
                                )}
                              </label>
                              </Tooltip>
                              <Tooltip title={translationUtils.getTranslation(
                                3330,'Distributor email for lead notification')} arrow placement='top'>
                              <input
                                type="text"
                                onChange={(event) => inputChangedHandler(event)}
                                className="fw-400"
                                name="distributorEmail"
                                placeholder={translationUtils.getTranslation(
                                  3285,
                                  'Distributor Email'
                                )}
                                readOnly={statusValue === 'RETIRED'}
                                value={
                                  rowData?.distributorEmail
                                    ? rowData?.distributorEmail
                                    : ''
                                }
                              />
                              </Tooltip>
                              {/* <label className="sl-Label">Source</label>
                              <p className='ptext'>{rowData?.source ? rowData?.source : ''}</p> */}
                            </div>
                          </Row>

                          <Row sm={2}>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  3262,
                                  'Source'
                                )}
                              </label>
                              <p className="ptext">
                                {rowData?.source ? rowData?.source : ''}
                              </p>
                            </div>
                          </Row>
                          {leadSource==='sforce'?
                          (<Row sm={2}>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  2049,
                                  'Group'
                                )}
                              </label>
                              <p className="ptext">
                                {rowData?.productGroup ? rowData?.productGroup : ''}
                              </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  72,
                                  'Division'
                                )}
                              </label>
                              <p className="ptext">
                                {rowData?.productDivisionName ? rowData?.productDivisionName : ''}
                              </p>
                            </div>
                          </Row>):null}
                          <h3>
                            {translationUtils.getTranslation(
                              3287,
                              'Contact Address'
                            )}
                          </h3>
                          <Row sm={2}>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  125,
                                  'Country'
                                )}
                              </label>
                              <p className="ptext">
                                {rowData?.country ? rowData?.country : ''}
                              </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  3288,
                                  'City or Town'
                                )}
                              </label>
                              <p className="ptext">
                                {rowData?.cityOrTown ? rowData?.cityOrTown : ''}
                              </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  3289,
                                  'Address Line 1'
                                )}
                              </label>
                              <p className="ptext">
                                {rowData?.addressLine1
                                  ? rowData?.addressLine1
                                  : ''}
                              </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <label className="sl-Label">
                                {translationUtils.getTranslation(125, 'County')}
                              </label>
                              <p className="ptext">
                                {rowData?.county ? rowData?.county : ''}
                              </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  3089,
                                  'Address Line 2'
                                )}
                              </label>
                              <p className="ptext">
                                {rowData?.addressLine2
                                  ? rowData?.addressLine2
                                  : ''}
                              </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  359,
                                  'Postal Code'
                                )}
                              </label>
                              <p className="ptext">
                                {rowData?.postalCode ? rowData?.postalCode : ''}
                              </p>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <label className="sl-Label">
                                {translationUtils.getTranslation(
                                  3290,
                                  'Address Line 3'
                                )}
                              </label>
                              <p className="ptext">
                                {rowData?.addressLine3
                                  ? rowData?.addressLine3
                                  : ''}
                              </p>
                            </div>
                          </Row>
                          {leadSource==='oec' ?
                          (<Row>
                            <h3 className="product-details-title">
                              {translationUtils.getTranslation(218, 'Products')}
                            </h3>
                            <DataTable
                              fixedHeader
                              fixedHeaderScrollHeight="600px"
                              columns={columns}
                              data={rowData ? rowData.products : []}
                              customStyles={customStyles}
                              responsive={true}
                              className={`${
                                rowData &&
                                rowData.products &&
                                rowData.products.length > 0
                                  ? 'product-details-table'
                                  : ''
                              }`}
                            />
                          </Row>):null}
                        </Form>
                      </>
                    ) : (
                      <div id="preloader" className="order-status-hifi">
                        <div className="loading-overlay">
                          &nbsp; <p></p>
                        </div>
                      </div>
                      // <p>
                      //   <img
                      //     src="../assets/img/parker-search-loading.gif"
                      //     alt="Loading..."
                      //   />
                      // </p>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Notes
                      setPage={setPage}
                      keyNumbers={keyNumbers}
                      setOpenAction={setOpenAction}
                      leadName={rowData?.leadName ? rowData?.leadName : ''}
                      setIsCommonLoading={setIsCommonLoading}
                      noteType={'GENERAL'}
                      distributorAccountNumber={
                        rowData?.distributorAccountNumber
                      }
                      setRefreshList={setRefreshList}
                      setNoteLoading={setNoteLoading}
                      noteloading={noteloading}
                      setAlertStatus={setAlertStatus}
                      setAlertMessage={setAlertMessage}
                      setModalIsOpen={setModalIsOpen}
                      setNoteCount={setNoteCount}
                      leadSource={leadSource}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </>
  );
};
export default DetailPage;
