import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { getAPIAuthKey, getHost } from "../../common-utils/apiconfig";
let customerId = window.sessionStorage.getItem("customerId");

export const getSalesLeads = createAsyncThunk('leads/getSalesLeads', async (dataS, { rejectWithValue }) => {
  try {
    
    const { data } = await axios(
      getHost() +
        `/LeadsApi/api/Leads/GetAllOpenLeads/offset/0/Limit/100/DistributorAccountNumber_c/${customerId}/totalResults/true/statusCode/${dataS}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "ocp-apim-subscription-key": getAPIAuthKey(),
        },
      }
    )
   // console.log("data===", data, getHost())
    return data.response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
})