import * as apis from '../common-utils/api_urls';
import axios from 'axios';
//import {getAPIAuthKey,getHeaderAuthKey} from '../common-utils/apiconfig'
import { headerdatastatic } from '../common-utils/headerdatastatic.js';

import { getAPIAuthKeyHeaderFooter, getEnviorment, getHost, getSubscriptionKey } from '../common-utils/apiconfig';
import langData from '../common-utils/languagecodes.json';
import { wcTokenApi } from '../common-utils/salesLeadsApi';
import { Cookies } from 'react-cookie';



export function getVarificationUrl() {
  if (window.location.hostname.toLowerCase().indexOf('localhost') >= 0) {
    return `https://loginqa.parker.com/api/v1/sessions/me`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleadsdev') >= 0
  ) {
    return `https://logintest.parker.com/api/v1/sessions/me`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleadstest') >= 0
  ) {
    return `https://loginqa.parker.com/api/v1/sessions/me`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleadsstage') >= 0
  ) {
    return `https://loginstage.parker.com/api/v1/sessions/me`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleads.parker') >= 0
  ) {
    return `https://login.parker.com/api/v1/sessions/me`;
  } else {
    return `https://logintest.parker.com/api/v1/sessions/me`;
  }
}

export function getHeaderFooterURL() {
  if (window.location.hostname.toLowerCase().indexOf('salesleadsdev') >= 0) {
    return `https://apidev.parker.com/dev`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleadstest') >= 0
  ) {
    return `https://apidev.parker.com/test`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleadsstage') >= 0
  ) {
    return `https://api.parker.com/stage`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleads.parker') >= 0
  ) {
    return `https://api.parker.com/prod`;
  } else {
    return `https://apidev.parker.com/test`;
  }
}
export function getApiHostUrl(){
  if (window.location.hostname.toLowerCase().indexOf('salesleadsdev') >= 0) {
    return `https://devmy.parker.com/`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleadstest') >= 0
  ) {
    return `https://testmy.parker.com/`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleadsstage') >= 0
  ) {
    return `https://stagemy.parker.com/`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleads.parker') >= 0
  ) {
    return `https://my.parker.com/`;
  } else {
    return `https://testmy.parker.com/`;
  }
}

export function varification() {
  try {
    const res = axios(getVarificationUrl(), {
      method: 'GET',
      withCredentials: true,
    })
      .then(function (result) {
        let response = result.data;
        window.sessionStorage.setItem('oktaSessionMeId', response.id);
        window.sessionStorage.setItem('oktaLogin', response.login);
        window.sessionStorage.setItem('oktaUserEmail', response.login);
        window.sessionStorage.setItem('oktaUserName', response._links.user.name);
        window.sessionStorage.setItem('oktaUserMeId', response.userId);
        
      })
      .catch(function (error) {
        window.sessionStorage.removeItem('oktaSessionMeId');
        window.sessionStorage.removeItem('oktaLogin');
        window.sessionStorage.removeItem('oktaUserEmail');
        window.sessionStorage.removeItem('oktaUserName');
        window.sessionStorage.removeItem('oktaUserMeId');

        console.error(error);
      });

    // Don't forget to return something
    return null;
  } catch (error) {
    window.sessionStorage.removeItem('oktaSessionMeId');
    window.sessionStorage.removeItem('oktaLogin');
    window.sessionStorage.removeItem('oktaLogin');
    console.error(error);
  }
}


// export function varification() {
//   if (window.location.hostname.toLowerCase().indexOf('localhost') === -1) {
//     axios(getVarificationUrl(), {
//       method: 'GET',
//       withCredentials: true,
//     })
//       .then(function (result) {
//         let response = result.data;
//         window.sessionStorage.setItem('oktaSessionMeId', response.id);
//         window.sessionStorage.setItem('oktaLogin', response.login);
//         // sessionStorage.setItem('expireTime',response.expiresAt)
//         //callingClass.setVarification(true);
//       })
//       .catch(function (error) {
//         // callingClass.setVarification(false);
//         window.sessionStorage.removeItem('oktaSessionMeId');
//         // window.location.href=`${window.allData.loginUrl}${window.location.href}`;
//       });
//   } else {
//     window.sessionStorage.setItem(
//       'oktaSessionMeId',
//       '102gah_R_dDSgWnHa4gPs7ZhA'
//     );
//   }
//   return '';
// }

export async function callHeaderFooter(cid, langCode, oktasessmeId) {
  console.log('oktasessmeId-1', oktasessmeId);
  try {
    if (oktasessmeId === null) {
      let res = headerdatastatic;
      console.log('resHeader', res);
      return res;
    } else {
      let res = await axios({
        url:
          getHeaderFooterURL() +
          '/hf/myparker-html-header-footer?cid=' +
          cid +
          '&oktaSessionId=' +
          oktasessmeId +
          '&langCode=' +
          langCode +
          '&supportAllLoc=true&orglist=true',
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'ocp-apim-subscription-key': getAPIAuthKeyHeaderFooter(),
        },
      });
      return res;
    }
  } catch (err) {
    console.error(err);
  }
}

export async function callHeaderFooter_local(cid, langCode, oktasessmeId) {
  try {
    let res = await axios({
      url:
        getHeaderFooterURL() +
        '/hf/myparker-html-header-footer?cid=' +
        cid +
        '&oktaSessionId=' +
        oktasessmeId +
        '&langCode=' +
        langCode +
        '&supportAllLoc=true',
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'ocp-apim-subscription-key': getAPIAuthKeyHeaderFooter(),
      },
    });

    //let res = headerdatastatic;
    // Don't forget to return something
    return res.data;
    //return res;
  } catch (err) {
    console.error(err);
  }
}

export async function getOrgList(history) {
  let curr_cid = window.location.search.includes('cid')
    ? window.location.search.split('?cid=')[1]
    : null;
  try {
    let res = await axios({
      url:
        getHeaderFooterURL() +
        `/delegatedadmin/store/20951/headerfooter/only/orglist/v1?langCode=EN&logonId=${window.sessionStorage.getItem(
          'oktaLogin'
        )}`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'ocp-apim-subscription-key': getAPIAuthKeyHeaderFooter(),
      },
    });
    let cont_contraId = '';
    let org_array = [];
    //let org_string = '';
    let tt = '';
    if (curr_cid === null) {
      res.data['header']['orgList'].map((orgItemm) => {
        if (orgItemm.isDefault) {
          cont_contraId = orgItemm.cid;
          sessionStorage.setItem('isDefaultCustomerId', cont_contraId);
           sessionStorage.setItem('customerId', orgItemm.legalId);
          //console.log('history inside varification===', history);
          history.push({
            pathname: history.location.pathname,
            search: '?cid=' + cont_contraId,
          });
        }
        tt = org_array.push(orgItemm.legalId);
        var array = org_array;
        tt = array.slice(0, 40).map((x) => `'${x}'`).join(',');
        // console.log(array.map(x=>`'${x}'`).toString())
    
        sessionStorage.setItem('completeOrgLegalId', tt);
      });
    }else{
      res.data['header']['orgList'].map((orgItemm) => {
      
        if (orgItemm.isDefault) {
          cont_contraId = orgItemm.cid;
          sessionStorage.setItem('isDefaultCustomerId', cont_contraId);
          //console.log("Orglist==",orgItemm)
          // sessionStorage.setItem('customerId', orgItemm.legalId);           
        }
        tt = org_array.push(orgItemm.legalId);
        var array = org_array;
        tt = array.slice(0, 40).map((x) => `'${x}'`).join(',');
        // console.log(array.map(x=>`'${x}'`).toString())
        sessionStorage.setItem('completeOrgLegalId', tt);
      });
    }
  
    //console.log('NewOrgList ===', org_array);
    //let res = headerdatastatic;
    // Don't forget to return something
    return res.data['header'];
    //return res;
  } catch (err) {
    console.error(err);
  }
}
// get default language start
export async function getWcToken() {
  const getToken = JSON.parse(window.localStorage.getItem('okta-token-storage'));
  const accessToken = getToken['accessToken']['accessToken'];
 // console.log('i am in  wcToken');
  const cookies = new Cookies();
  try {
    let res = await axios({
      url:
        getHost() + wcTokenApi,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'ocp-apim-subscription-key': getAPIAuthKeyHeaderFooter(),
      },
      data: {
        logonId: window.sessionStorage.getItem('oktaLogin'),
        oktaSessionId: accessToken
      },
    });
    // Don't forget to return something
    //console.log("wcToken==",res)
    if(res.data!==null && res.data!==undefined){
      window.sessionStorage.setItem('WCToken', res.data['WCToken']);
      window.sessionStorage.setItem('WCTrustedToken', res.data['WCTrustedToken']);
      window.sessionStorage.setItem('userId', res.data['userId']);
      
      // console.log("WcToken =",res.data['WCToken']);
       //console.log("WCTrustedToken =", {path:'/',domain:'.parker.com'});

      /* WCToken and WCTrusted set into cookies */

      cookies.set(getEnviorment()+'_WCToken', decodeURIComponent(res.data['WCToken']),{path:'/',domain:'.parker.com'});
      cookies.set(getEnviorment()+'_WCTrustedToken', decodeURIComponent(res.data['WCTrustedToken']),{path:'/',domain:'.parker.com'});
   
      //getDefaultLang()
      return null; //res.data;
      //return res;
    }
  } catch (err) {
    console.error(err);
  }
}

export async function getDefaultLang() {
  
  try {
    let res = await axios({
      url:
        getHeaderFooterURL() + `/users/store/20951/person/@self`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'ocp-apim-subscription-key': getSubscriptionKey(),
        'WCtoken': window.sessionStorage.getItem('WCToken'),
        'WCTrustedToken' : window.sessionStorage.getItem('WCTrustedToken'),
      },
    });
    
   console.log("Self", res.data) 
   var langResponse = res.data.contextAttribute
   .find(m => m.attributeName === 'MPLangId')
   ?.attributeValue.find(m => m.storeId === '20951').value[0];
    let mplLangId = res.data.contextAttribute.find(m => m.attributeName === 'MPLangId')
    if(res.data && (langResponse!==undefined || langResponse!=='') && mplLangId !== undefined){ 
      
      let customerLang =langData[langResponse];
      let defaultLang = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);//window.sessionStorage.getItem('defaultLang');
      let defaultLangCode =  langResponse; //res.data.contextAttribute[0].attributeValue[0].value[0];
      //console.log("defaultLang value", defaultLangCode );
      if(defaultLang==='' || defaultLang===null ){
        console.log('lang in url', defaultLang)
        let customerLangCode = customerLang;
       // console.log("Lg1 when lang blank in url", customerLangCode)
        if (customerLangCode === 'undefined'||  defaultLangCode === '' | defaultLangCode===null) {
          //console.log("en in  url because lang code null") 
          window.location.href =
          window.location.origin +
          window.location.pathname.replace(
            window.location.pathname.substring(window.location.pathname.lastIndexOf('home/')),
          "home/en") + window.location.search;
          window.sessionStorage.setItem('defaultLang', customerLang); 
          return "en";
        }else{
          console.log("in else condition for default lang", customerLangCode)
          console.log("en in  url because lang code null") 
          window.location.href =
          window.location.origin +
          window.location.pathname.replace(
            window.location.pathname.substring(window.location.pathname.lastIndexOf('home/')),
          "home/"+customerLang) + window.location.search;
          window.sessionStorage.setItem('defaultLang', customerLang); 
          return customerLang;
        }
      }
      // if((defaultLang==='' || defaultLang===null ) && (defaultLangCode!==null || defaultLangCode!=='null' || defaultLangCode!==''))
      // {
      //   console.log("Lg1",langData[res.data.contextAttribute[0].attributeValue[0].value[0]])
      //   window.location.href =
      //   window.location.origin +
      //   window.location.pathname.replace(
      //     window.location.pathname.substring(window.location.pathname.lastIndexOf('home/')),
      //   "home/"+customerLang) + window.location.search;
      //   window.sessionStorage.setItem('defaultLang', customerLang);
      //   return langData[res.data.contextAttribute[0].attributeValue[0].value[0]];
      //   }else{
      //             window.location.href =
      //       window.location.origin +
      //       window.location.pathname.replace(
      //         window.location.pathname.substring(window.location.pathname.lastIndexOf('home/')),
      //       "home/en") + window.location.search;
      //       window.sessionStorage.setItem('defaultLang', customerLang);
      //       return 'en';
      //   }
      
      
      
    }
  
    else{
      console.log("if attribute not found") 
      window.location.href =
      window.location.origin +
      window.location.pathname.replace(
        window.location.pathname.substring(window.location.pathname.lastIndexOf('home/')),
      "home/en") + window.location.search; 
      return 'en';
    }
  } catch (err) {
    console.error(err);
    window.location.href =
      window.location.origin +
      window.location.pathname.replace(
        window.location.pathname.substring(window.location.pathname.lastIndexOf('home/')),
      "home/en") + window.location.search; 
      return 'en';
  }
}

// get default language end


export async function callApiToFetchIP() {
  try {
    const response = await axios.get(getApiHostUrl()+'api/user-ip-address',
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
       
     // setClientIp(response.data.ip_address)
     
     window.sessionStorage.setItem('clientIp', response.data.ip_address);
      console.log("ip",response.data.ip_address)
  } catch (error) {
    console.error('Error:', error);
  }
}
