import React from 'react';
import { Modal } from 'react-bootstrap';
import cross from './cross.svg';
import * as translationUtils from '../../common-utils/translation_utils';
const CustomConfirmation = (props) => {
  const status = props.alertStatus;
  // const statusElementId =
  return (
    <Modal
      show={props.isOpen}
      onHide={props.confirmationClose}
      dialogClassName="modal_popup"
    >
      <div className="modal-header rm-bb">
        <div className="col-lg-12 modal-header-close">
          <img
            src={cross}
            className="cross_img"
            onClick={props.confirmationClose}
            alt="close"
          />
        </div>
      </div>
      <div className="modal-body ">
        <div className="col-lg-12 sl-status">
          <div className="status-class">
            {props.alertStatus === 'Success' ? (
              <>
                <img
                  alt="Success"
                  src="/assets/img/success.svg"
                  width={60}
                  height={60}
                />
                <p>
                  <span className="sl-status-type">
                    {translationUtils.getTranslation(2040, `${status}`)}
                  </span>
                </p>
              </>
            ) : (
              <>
                <img
                  alt="Failed"
                  src="/assets/img/fail.svg"
                  width={60}
                  height={60}
                />
                <p>
                  <span className="sl-status-fail">{props.alertStatus}</span>
                </p>
              </>
            )}

            <p>
              <strong className="sl-status-message">
                {props.alertMessage}
              </strong>
            </p>
          </div>
        </div>
      </div>
      <div className="modal-footer modal-footer-status modal-footer rm-bt">
        <button
          className="transparent-button set_btn_spnce btn btn-primary"
          style={{ float: 'right' }}
          onClick={props.confirmationClose}
        >
          {translationUtils.getTranslation(676, 'Close')}
        </button>
      </div>
    </Modal>
  );
};
export default CustomConfirmation;
