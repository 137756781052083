import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import './createNotes.css'; 
import { getAPIAuthKey, getHost } from '../../../../common-utils/apiconfig';
import axios from 'axios'; 
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from "html-to-draftjs";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; 
import * as translationUtils from '../../../../common-utils/translation_utils';
import { createNoteApi, updatNoteApi } from '../../../../common-utils/salesLeadsApi';

const CreateNotes = (props) => {
 // const dispatch = useDispatch();
 // const xx = useSelector((state) => state.default.isCreateNote);

  const [noteType, setNoteType] = useState('GENERAL');
  const [noteTitle, setNoteTitle] = useState('');
  const [noteTxt, setNoteTxt] = useState(EditorState.createEmpty());

  const [isNoteCreated, setIsNoteCreated] = useState(false);
  const [noteID, setNoteID] = useState();
  const [itemData, setItemData] = useState();
 

  const onChangeTitle = (event) => {
    // dispatch(noteTitle(event.target.value));
    setNoteTitle(event.target.value);
  };

  const onEditorStateChange = (editorTxt) => {
    setNoteTxt(editorTxt);
  };

  const onClickCreateNote = (isClose) => {
    if (validation()) {
      submitNote(isClose);
    }
  };

  const validation = () => {
    let isValidate = true;
    if (noteType === '') {
      alert('Please select type');
      isValidate = false;
    } else if (noteTitle === '') {
      alert('Please enter title');
      isValidate = false;
    } else if (
      (convertToRaw(noteTxt.getCurrentContent()).blocks.length === 1) &
      (convertToRaw(noteTxt.getCurrentContent()).blocks[0].text === '')
    ) {
      alert('Please enter description');
      isValidate = false;
    }
    return isValidate;
  };

  const submitNote = async (isClose) => {
    props.setIsCommonLoading(true);
    const body = {
      noteTypeCode: noteType,
      noteTxt: draftToHtml(convertToRaw(noteTxt.getCurrentContent())),
      noteTitle: noteTitle,
      noteId: 0,
      mppDistNumber_c: props.distributorAccountNumber,
    };

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'ocp-apim-subscription-key': getAPIAuthKey(),
      },
    };

    if (isNoteCreated === false) {
        await axios
        .post(
          getHost() + createNoteApi + props.keyNumbers+`/SourceSystem/${props.leadSource}`,
          body,
          headers
        )
        .then((response) => {
          if (response?.data?.status === 1) {
            setItemData(response?.data);
            setNoteID(response?.data?.response?.noteId);
            setIsNoteCreated(true);
            props.setRefreshList(response?.data?.response?.lastUpdateDate);
            props.setIsCommonLoading(false); 
            props.setModalIsOpen(true);
            props.setAlertStatus('Success');
            props.setAlertMessage('Note created successfully!!');

            if (isClose) {
              props.setIsShown(false);
              //console.log("isclose", isClose)
              reset();
            } 
          } else {
            props.setModalIsOpen(true);
            props.setAlertStatus('Failed');
            props.setAlertMessage('Oops! Note created unsuccessful.!!');
            props.setIsCommonLoading(false);
            if (isClose) {
              props.setIsShown(false);
              reset();
            }
          }
        })
        .catch((error) => {
          console.log(error);
          // props.setModalIsOpen(true);
          // props.setAlertStatus('Failed');
          // props.setAlertMessage('Oops! Something went wrong.!!');
          // props.setIsCommonLoading(false);
        });
    } else {
      const url =
        getHost() + updatNoteApi + `${props.keyNumbers}/SourceSystem/${props.leadSource}/Child/Note/${noteID}`;
      let options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          dataType: 'json',
          'ocp-apim-subscription-key': getAPIAuthKey(),
        },
        data: body,
        url,
      };

      await axios(options)
        .then((response) => {
          if (response?.data?.status === 1) {
            // console.log('list refresh', response?.data?.response?.lastUpdateDate);
            setItemData(response?.data);
            setNoteID(response?.data?.response?.noteId);
            setIsNoteCreated(true);
            props.setRefreshList(response?.data?.response?.lastUpdateDate);
            props.setIsCommonLoading(false);
            props.setModalIsOpen(true);
            props.setAlertStatus('Success');
            props.setAlertMessage('Note updated successfully!!');
            if (isClose) {
              props.setIsShown(false);
              reset();
            }  
          } else {
            props.setModalIsOpen(true);
            props.setAlertStatus('Failed');
            props.setAlertMessage('Oops! Note update unsuccessful.!!');
            props.setIsCommonLoading(false);
            if (isClose) {
              props.setIsShown(false);
              reset();
            }
          }
        })
        .catch((er) => {
         // console.log('no data sorry ', er);
          props.setIsCommonLoading(false);
        });
    }
  };

  const reset = () => {
    setNoteType('GENERAL');
    setNoteTitle('');
    setNoteTxt(EditorState.createEmpty());
  };

  return (
    <>
    {props.leadSource==='sforce'? (
       <p className='warning_msg'><i className='fa fa-info-circle'></i> <b>Attention</b> : All Notes are public and can be viewed by any user with access to this lead.</p>
    ):(null)}
      <Row>
        <Col sm={12} className="pad-0">
          <div className="col-lg-12 col-md-12 col-sm-12 lead_name">
            {props.leadName}
          </div>
        </Col>
      </Row>
      <Row className="">
        {/* <Col sm={6} className="pad-0">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <label className="sl-Label">
              {translationUtils.getTranslation(66, 'Type')}
            </label>
            <input
              id="txtNoteType"
              key="txtNoteType"
              type="text"
              name="type"
              value={translationUtils.getTranslation(3296, 'General')}
              readOnly
            />
            
          </div>
        </Col> */}
        <Col sm={6} className="pad-0">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <label className="sl-Label">
              {translationUtils.getTranslation(42, 'Title')}
            </label>
            <input
              id="txtNoteTitle"
              key="txtNoteTitle"
              type="text"
              name="title"
              placeholder={translationUtils.getTranslation(
                3295,
                'Enter title here'
              )}
              maxLength="500"
              onChange={onChangeTitle}
              value={noteTitle}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="pad-0">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <label className="sl-Label">
              {translationUtils.getTranslation(102, 'Description')}
            </label>
            <Editor
              toolbarHidden="true"
              editorState={noteTxt}
              onEditorStateChange={onEditorStateChange}
              placeholder={translationUtils.getTranslation(
                3294,
                'Enter description'
              )}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="pad-0">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="note_btn_container">
              <button
                className="transparent-button cancel_note note_button"
                onClick={() => {
                  props.setIsShown(false);
                  props.setPage('Lead Notes');
                }}
                // onClick={validation}
                // onClick={() =>
                //   alert(
                //     btoa(
                //       unescape(
                //         encodeURIComponent(
                //           draftToHtml(convertToRaw(noteTxt.getCurrentContent()))
                //         )
                //       )
                //     )
                //   )
                // }
              >
                {translationUtils.getTranslation(404, 'Cancel')}
              </button>
              <button
                className="transparent-button note_button"
                onClick={() => onClickCreateNote(true)}
              >
                {translationUtils.getTranslation(3266, 'Save & Close')}
              </button>

              <button
                className="transparent-button note_button btn_act"
                onClick={() => onClickCreateNote(false)}
              >
                {translationUtils.getTranslation(1075, 'Save')}
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default CreateNotes;
