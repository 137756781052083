import React from 'react';
import Select from 'react-select';

const CustomSelect = (props) => {
  const getDefaultValueObj = (value) => {
    if (props.options) {
      return props.options.find((option) => option.value === value);
    }
    return '';
  };

  const colourStyles = () => ({
    control: (styles, { isFocused }) => ({
      ...styles,
      borderRadius: '0',
      height: '40px',
      border: isFocused ? '1px solid #222' : '1px solid #000000',
      boxShadow: isFocused
        ? 'inset 0 1px 1px rgb(0 0 0 / 10%), 0 0 6px #6f6f6f'
        : undefined,
      borderColor: '#000000',
    }),
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        color: '#000',
        backgroundColor: isSelected ? '#ffb91d' : undefined,
        ':active': {
          ...styles[':active'],
          backgroundColor: '#ffb91d',
        },
        ':hover': {
          ...styles[':hover'],
          backgroundColor: '#f1af1cc7',
        },
      };
    },
    input: (styles) => ({ ...styles, boxShadow: 'none' }),
  });

  return (
    <Select
      {...props}
      className={`custom-select-box ${props.className ? props.className : ''}`}
      styles={colourStyles()}
      defaultValue={
        props.defaultValue ? getDefaultValueObj(props.defaultValue) : null
      }
      value={props.value ? getDefaultValueObj(props.value) : null}
    />
  );
};
export default CustomSelect;
