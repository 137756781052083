import axios from "axios";
import { getHost, getAPIAuthKey } from "../common-utils/apiconfig";

export default axios.create({
  baseURL: getHost(),
  headers: {
    "Content-Type": "application/json",
    "ocp-apim-subscription-key": getAPIAuthKey(),
  },
});
