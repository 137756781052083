import { configureStore, combineReducers } from "@reduxjs/toolkit";
import DefaultSlice from "./default/default_slice";
import salesLeadsSlice from "./salesLeads/salesLeadsSlice";
import notesSlice from "./notes/notesSlice";

const rootReducer = combineReducers({
  default: DefaultSlice.reducer,
  salesLeads:salesLeadsSlice,
  notesSlice:notesSlice
});

export const store = configureStore({
  reducer: rootReducer,
});
