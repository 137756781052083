import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import './createNotes.css'; 
import { getAPIAuthKey, getHost } from '../../../../common-utils/apiconfig';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'; 
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from "html-to-draftjs";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import useUnsavedChangesWarning from '../../../hooks/useUnsavedChangesWarning';
import * as translationUtils from '../../../../common-utils/translation_utils';
import { updatNoteApi } from '../../../../common-utils/salesLeadsApi';

import InnerHTML from 'dangerously-set-html-content'

const ViewNoteDetail = ({
  distributorAccountNumber,
  keyNumbers,
  leadName,
  noteId,
  setIsShown,
  setRefreshList,
  itemData,
  setIsCommonLoading,
  setLoading,
  setAlertStatus,
  setAlertMessage,
  setModalIsOpen,
  setPage,
  leadSource,
}) => {
  const dispatch = useDispatch();
  const xx = useSelector((state) => state.default.isCreateNote);
  const [noteloading, setNoteLoading] = useState(true);
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

  const [noteType, setNoteType] = useState('');
  const [noteTitle, setNoteTitle] = useState('');
  const [html, setHtml] = useState('');
  
  
  
   
  // const [noteTxt, setNoteTxt] = useState(() =>
  //   EditorState.createWithContent(
  //     ContentState.createFromBlockArray(convertFromHTML(itemData?.noteTxt))
  //   )
  // ); 
    const [noteText, setNoteText] = useState(itemData?.noteTxt);
  
  const [noteTxt, setNoteTxt] = useState('');
  const [rowData, setRowData] = useState({
    createdBy: '',
    creationDate: '',
    lastUpdateDate: '',
    noteId: '',
    noteNumber: '',
    noteTitle: '',
    noteTxt: '',
    noteTypeCode: '',
  });
  const [noteEdit, setNoteEdit] = useState(false);

  useEffect(() => {
    if (typeof noteId !== 'undefined') {
      //dispatch(getNotesDetails({noteId}))
      
    
    }
    setNoteTxt(EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(itemData?.noteTxt??''))
    ))
    setHtml(itemData?.noteTxt);
    setNoteText(itemData?.noteTxt)
    setRowData(itemData);
    setNoteLoading(false);
  }, [noteId, itemData]);

  const onChangeType = (selected) => {
    setRowData({ ...rowData, noteTypeCode: selected.value });
  };
  const onEditorStateChange = (editorTxt) => {
    setNoteTxt(editorTxt);

    let descHTML = draftToHtml(convertToRaw(editorTxt.getCurrentContent()));
    setRowData({ ...rowData, noteTxt: descHTML });
  };

  const onClickCreateNote = (isClose) => {
    if (validation()) {
      submitNote(isClose);
    }
  };
  const onClickBack = (isClose) => {
    //setRefreshList(noteId);
    setIsShown(false);
  };

  const inputChangedHandler = (event) => {
    setRowData({ ...rowData, [event.target.name]: event.target.value });
    setDirty();
  };
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const getFormattedDate = (newDate) => {
    let objectDate = new Date(newDate);
    let day = objectDate.getDate();
    let month = monthNames[objectDate.getMonth()];
    let year = objectDate.getFullYear();
    let hrs = objectDate.getHours();
    let mins = objectDate.getMinutes();
    let sec = objectDate.getSeconds();
    let format1 =
      month + '/' + day + '/' + year + ' ' + hrs + ':' + mins + ':' + sec;
    return format1;
  };
  const validation = () => {
    let isValidate = true;
    if (rowData.noteType === '') {
      alert('Please select type');
      isValidate = false;
    } else if (rowData.noteTitle === '') {
      alert('Please enter title');
      isValidate = false;
    } else if (rowData.noteTxt === '') {
      alert('Please enter text');
      isValidate = false;
    }
    return isValidate;
  };
  
  const submitNote = async (isClose) => {
    // setDisabledSave(true);
    let noteId = rowData.noteId;

    const data = {
      noteTypeCode: rowData.noteTypeCode,
      noteTxt: rowData.noteTxt,
      noteTitle: rowData.noteTitle,
      // creatorPartyEmailId: "vasanthramalingam@parker.com",
      noteId: noteId,
      mppDistNumber_c: distributorAccountNumber,
    };

    setIsCommonLoading(true);

    const url =
      getHost() + updatNoteApi + `${keyNumbers}/SourceSystem/${leadSource}/Child/Note/${noteId}`;
    let options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        dataType: 'json',
        'ocp-apim-subscription-key': getAPIAuthKey(),
      },
      data: data,
      url,
    };
    await axios(options)
      .then((res) => {
        if (res?.data?.status === 1) {
          //alert("mani");
          setModalIsOpen(true);
          setAlertStatus('Success');
          setAlertMessage('Note updated successfully!!');
          setIsCommonLoading(false);
          setRefreshList(res?.data?.response?.lastUpdateDate);

          //console.log('yeh we have', res);
          if (isClose) {
            setIsShown(false);
          }
        } else {
          setIsCommonLoading(false);
          setModalIsOpen(true);
          setAlertStatus('Failed');
          setAlertMessage('Oops! Note update unsuccessful.!!');
          if (isClose) {
            setIsShown(false);
            setRefreshList(res?.data?.response?.lastUpdateDate);
          }
        }
      })
      .catch((er) => {
        console.log('no data sorry ', er);
        setModalIsOpen(true);
        setAlertStatus('Failed');
        setAlertMessage('Something went wrong.!!');
        setIsCommonLoading(false);
      });
    //  if(typeof keyNumbers !== "undefined" && typeof noteId !== "undefined"){
    //   let res= dispatch(updateNote({ keyNumbers, noteId, data }))
    //   if(res) {
    //    alert("Note Update successfully.")
    //    setIsCommonLoading(false);
    //    setDisabledSave(false);
    //   }
    //    if (isClose) {
    //      setIsShown(false);
    //    }
    //  }
  };

  const reset = () => {
   
    setRowData('')
    setNoteType('');
    setNoteTitle('');
    setNoteTxt(EditorState.createEmpty());
  };

  const closeDetails = () => {
    setRefreshList(noteId);
    setIsShown(false);
  };
  const confirmationClose = () => {
    setModalIsOpen(false);
  };

  
  console.log("Html in view", rowData)
  return (
    <>
    {leadSource==='sforce'? (
       <p className='warning_msg'><i className='fa fa-info-circle'></i> <b>Attention</b> : All Notes are public and can be viewed by any user with access to this lead.</p>
    ):(null)}
      {/* <CustomConfirmation isOpen={modalIsOpen}  
        confirmationClose={confirmationClose} 
        alertStatus={alertStatus}
        alertMessage={alertMessage}/> */}
        
      {!noteloading ? (
        <>
          {Prompt}
          <Row
            style={{ position: 'relative', flexDirection: '' }}
            className="noteAction1"
          >
            <Col lg={6}>
              <p>
                <b>{leadName}</b>
              </p>
            </Col>
            {/* <Col sm={3} className="head-button-wrapper-left">
                            <Button className="transparent-button" onClick={() => setIsShown(false)}>
                            Cancel
                            </Button>
                          </Col> */}

            <Col lg={6} className="head-button-wrapper">
              {noteEdit === false ? (
                <>
                  <Button
                    className="transparent-button btn_cancel btn_cwidth"
                    onClick={() =>{setIsShown(false); setPage("Lead Notes");}}
                  >
                    {translationUtils.getTranslation(547, 'Back')}
                  </Button>
                  
                  {rowData?.createdBy === 'ParkerDotCom.IDCSIntUser' || leadSource==='sforce'? (
                    <Button
                      className="transparent-button btn_cwidth"
                      onClick={() => setNoteEdit(true)}
                    >
                      {translationUtils.getTranslation(1167, 'Edit')}
                    </Button>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <Button className="transparent-button btn-gray"> {translationUtils.getTranslation(1167, 'Edit')}</Button>
              )}
             
            </Col>
          </Row>
          <Row className="">
            <Col sm={6} className="pad-0">
              {noteEdit === false ? (
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <label className="sl-Label CamelC">
                    {translationUtils.getTranslation(1362, 'Created')}
                  </label>
                  <input
                    id="txtNoteCreate"
                    key="txtNoteCreate"
                    type="text"
                    name="creationDate"
                    placeholder="Created Date"
                    maxLength="500"
                    // onChange={onChangeTitle}
                    onChange={(event) => inputChangedHandler(event)}
                    value={
                      rowData?.creationDate
                        ? getFormattedDate(rowData?.creationDate)
                        : ''
                    }
                    readOnly
                  />
                </div>
              ) : (
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <label className="sl-Label">
                    {translationUtils.getTranslation(42, 'Title')}
                  </label>
                  <input
                    id="txtNoteTitle"
                    key="txtNoteTitle"
                    type="text"
                    name="noteTitle"
                    placeholder="Enter title here"
                    maxLength="500"
                    //onChange={onChangeTitle}
                    onChange={(event) => inputChangedHandler(event)}
                    value={rowData?.noteTitle ? rowData?.noteTitle : ''}
                    // readOnly={noteEdit}
                  />
                </div>
              )}
            </Col>

            {noteEdit === false ? (
              <>
                <Col sm={6} className="pad-0">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label className="sl-Label">
                      {translationUtils.getTranslation(3297, 'Updated')}
                    </label>
                    <input
                      id="txtNoteUpdated"
                      key="txtNoteUpdated"
                      type="text"
                      name="lastUpdateDate"
                      placeholder="Updated"
                      maxLength="500"
                      //onChange={onChangeTitle}
                      onChange={(event) => inputChangedHandler(event)}
                      value={
                        rowData?.lastUpdateDate
                          ? rowData?.lastUpdateDate === 'Never'
                            ? 'Never'
                            : getFormattedDate(rowData?.lastUpdateDate)
                          : ''
                      }
                      readOnly
                    />
                  </div>
                </Col>
              </>
            ) : (
              ''
            )}
          </Row>
          {noteEdit === false ? (
            <>
              <Row className="">
                {/* <Col sm={6} className="pad-0">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label className="sl-Label">Updated</label>
                    <input
                      id="txtNoteUpdated"
                      key="txtNoteUpdated"
                      type="text"
                      name="lastUpdateDate"
                      placeholder="Updated"
                      maxLength="500"
                      //onChange={onChangeTitle}
                      onChange={(event) => inputChangedHandler(event)}
                      value={
                        rowData?.lastUpdateDate
                          ? rowData?.lastUpdateDate === 'Never'
                            ? 'Never'
                            : getFormattedDate(rowData?.lastUpdateDate)
                          : ''
                      }
                      readOnly
                    />
                  </div>
                </Col> */}
                {/* <Col sm={6} className="pad-0">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label className="sl-Label">Author</label>
                    <input
                      id="txtNoteAuthor"
                      key="txtNoteAuthor"
                      type="text"
                      name="createdBy"
                      placeholder="Author"
                      maxLength="500"
                      //onChange={onChangeTitle}
                      onChange={(event) => inputChangedHandler(event)}
                      value={rowData?.createdBy ? rowData?.createdBy : ''}
                      readOnly
                    />
                  </div>
                </Col> */}
              </Row>
              <Row className="">
                <Col sm={12} className="pad-0">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label className="sl-Label">
                      {translationUtils.getTranslation(42, 'Title')}
                    </label>
                    <input
                      id="txtNoteTitle"
                      key="txtNoteTitle"
                      type="text"
                      name="noteTitle"
                      placeholder="Enter title here"
                      maxLength="500"
                      //onChange={onChangeTitle}
                      onChange={(event) => inputChangedHandler(event)}
                      value={rowData?.noteTitle ? rowData?.noteTitle : ''}
                      readOnly={noteEdit === false}
                    />
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            ''
          )}
          <Row>
            <Col sm={12} className="pad-0">
              <div className="col-lg-12 col-md-12 col-sm-12">
                {html===undefined ?(
                //   <Editor
                //   toolbarHidden="true"
                //   editorState={noteTxt}
                //   onEditorStateChange={onEditorStateChange}
                //   placeholder="Enter text"
                //   readOnly={noteEdit === false}
                // />
                null

                ):(
                // <InnerHTML html={html}

                //   className='rdw-editor-main'
                //   placeholder="Enter text"
                //   readOnly={noteEdit === false} />
                <Editor
                toolbarHidden="true"
              editorState={noteTxt}
              onEditorStateChange={onEditorStateChange}
              placeholder="Enter text"
              readOnly={noteEdit === false}
            />
                  
                  )}
                {/*  */}
                
              </div>
            </Col>
          </Row>
          {noteEdit ? (
            <Row>
              <Col sm={12} className="pad-0">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="note_btn_container">
                    <button
                      className="transparent-button cancel_note note_button"
                      onClick={() => onClickBack(false)}
                    >
                      {translationUtils.getTranslation(404, 'Cancel')}
                    </button>
                    <button
                      className="transparent-button note_button"
                      onClick={() => onClickCreateNote(true)}
                    >
                      {translationUtils.getTranslation(3266, 'Save & Close')}
                    </button>

                    <button
                      className="transparent-button note_button"
                      onClick={() => onClickCreateNote(false)}
                    >
                      {translationUtils.getTranslation(1075, 'Save')}
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            ''
          )}
        </>
      ) : (
        <p>
          <img src="../assets/img/parker-search-loading.gif" alt="Loading..." />
        </p>
      )}
    </>
  );
};
export default ViewNoteDetail;
