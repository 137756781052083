export const createNoteHandler = (state, action) => {
  state.isCreateNote = true;
};

export const noteTypeHandler = (state, action) => {
  state.note.noteType = action.payload;
};

export const noteTitleHandler = (state, action) => {
  state.note.noteTitle = action.payload;
};

export const noteTxtHandler = (state, action) => {
  state.note.noteTxt = action.payload;
};
