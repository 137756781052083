export const getAccessToken = () => {
    let accessToken;
    let token = localStorage.getItem('okta-token-storage');
    let sessionID = JSON.parse(token).accessToken.value;
    let session_ID = sessionStorage?.getItem("accessToken");
    if (session_ID === 'undefined'){
        accessToken = sessionID;
    } else if (session_ID !== 'undefined'){
        accessToken = session_ID;
    }
    return accessToken;
}