import { createSlice } from "@reduxjs/toolkit";
import { createNotes, retrieveAllNotes, getNotesDetails, updateNote, getNotesDetailsById } from "./actionNotes";
const initialState = [];

const notesSlice = createSlice({
  name: "note",
  initialState,
  extraReducers: {
    [createNotes.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
    [retrieveAllNotes.fulfilled]: (state, action) => {
      //console.log("action==", action.payload)
      return [...(action.payload || [])];
    },
    [updateNote.fulfilled]: (state, action) => {
     // console.log("index====", state,action, action.payload.response)

      const index = state.findIndex(item => item.noteId === action.payload.response?.noteId);

      state[index] = {
        ...state[index],
        ...action.payload.response,
      };
     // console.log("state====", state)

      return state;
    },
    [getNotesDetails.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [getNotesDetailsById.fulfilled]: (state, action) => {
      console.log("action==", action)
      return [...action.payload];
    }
       
  },
});

export default notesSlice.reducer;