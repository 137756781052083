export const getLeads = `LeadsApi/api/Leads/GetAllOpenLeads/`;
export const getLeadData = `LeadsApi/api/Leads/GetLeadsWithRequiredFields/LeadNumber/`;
export const editLeadInfo = `LeadsApi/api/Leads/EditLeadInfo/LeadNumber/`;
export const retireLeadApi = `/LeadsApi/api/Leads/RetiringLead/LeadNumber/`;
export const rejectLeadApi = `LeadsApi/api/Leads/RejectLead/LeadNumber`;
export const createNoteApi = `LeadsApi/api/Notes/CreateLeadNotes/LeadNumber/`;
export const updatNoteApi = `LeadsApi/api/Notes/EditLeadNote/LeadNumber/`;
export const wcTokenApi = `delegatedadmin/store/20951/loginidentity/oktaSession`;
export const getDefaultLangApi = `/users/store/20951/person/@self`;
export const getNoteById = `/LeadsApi/api/Notes/GetNoteDetailsById/LeadNumber/`;
export const getRetireReasonList =`/LeadsApi/api/Leads/Picklist/RetireReasons`
