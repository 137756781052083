import http from "./http.axios";

class NotesService {
  getAll(leadSource, key,data) {
    return http.post(`/LeadsApi/api/Notes/GetNotesDetailWithLocations/LeadNumber/${key}/SourceSystem/${leadSource}`, data);
  }
  get(keyNumbers) {
    return http.get(`/LeadsApi/api/GetNotesDetail/LeadNumber/${keyNumbers}`);
  }
  create(keyNumbers, data) {
    return http.post(`/LeadsApi/api/Notes/CreateLeadNotes/LeadNumber/${keyNumbers}`, data);
  }
  update(keyNumbers,NoteId, data) {
    return http.patch(`/LeadsApi/api/Notes/EditLeadNote/LeadNumber/${keyNumbers}/Child/Note/${NoteId}`, data);
  }
  getNoteById(keyNumbers, leadSource, noteId){
    return http.get(`/LeadsApi/api/Notes/GetNoteDetailsById/LeadNumber/${keyNumbers}/SourceSystem/${leadSource}/Child/Note/${noteId}`);
  }
}

export default new NotesService();



//api/Notes/GetNoteDetailsById/LeadNumber/${keyNumbers}/SourceSystem/${SourceSystem}/Child/Note/${NoteId}
//api/Notes/GetNoteDetailsById/LeadNumber/1132/SourceSystem/sforce/Child/Note/0697z000002xRHZAA2