import axios from 'axios';
import * as apis from './api_urls';
import {
  getAPIAuthKey,
  getAPIAuthKeyHeaderFooter,
} from '../common-utils/apiconfig';
import { getAccessToken } from './util';

const ids = [
  3252, 3253, 3254, 3255, 3256, 3257, 3258, 3259, 3260, 3261, 3262, 3263, 3264,
  3265, 3266, 3267, 3268, 3269, 3270, 3271, 3272, 3273, 3274, 3275, 3276, 3277,
  3278, 3279, 3280, 3281, 3282, 3283, 3284, 3285, 3286, 3287, 3288, 3289, 3290,
  3291, 3292, 3293, 3294, 3295, 3296, 3297, 3298, 3299, 3300, 3301, 3302, 3303,
  3304, 791, 3318, 3319, 3259, 65, 1842, 3260, 355, 120, 114, 3261, 443, 3262,
  218, 3263, 3279, 547, 3266, 1075, 1064, 1809, 3265, 648, 1750, 3321, 3322,
  125, 359, 3082, 2049, 72, 1565, 751, 66, 42, 102, 404, 751, 57, 676, 1207,
  3297, 1167, 1362, 3089, 88, 3324, 3325, 3326, 3327, 3328, 3329, 3330, 1453, 1207
];
var translations = null;
var divisionNames = null;

/**
 * Set Translations as per locale
 * @param {string} locale
 */
export function setTranslations(callingClass, locale) {
  axios(apis.getTranslationUrl(), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'ocp-apim-subscription-key': getAPIAuthKeyHeaderFooter(),
    },
    data: {
      translations: {
        ids: ids.join(','),
        locales: locale,
      },
    },
  })
    .then((result) => {
      let response = result.data;
      //console.log(response);
      translations = response;
      //callingClass.translationCallback(locale);
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getTranslation(id, defaultWord) {
  if (translations !== null) {
    if (translations[id] !== undefined) {
      if (
        translations[id][Object.keys(translations[id])[0]] !== undefined &&
        translations[id][Object.keys(translations[id])[0]] !== ''
      ) {
        var parser = new DOMParser();
        defaultWord = parser.parseFromString(
          translations[id][Object.keys(translations[id])[0]],
          'text/html'
        ).body.textContent;

        return defaultWord;
      } else return defaultWord;
    } else {
      return defaultWord;
    }
  } else {
    return defaultWord;
  }
}

/**
 * Set Division Id as per the name
 *  * @param {string} locale
 */
export async function setDivision() {
  await axios(apis.getDivisionUrl(), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'ocp-apim-subscription-key': getAPIAuthKey(),
      account: window.sessionStorage.getItem('customerId'),
      useremail: window.sessionStorage.getItem('oktaLogin'),
      sessionid: getAccessToken(),

    },
  })
    .then((result) => {
      let response = result.data;
      divisionNames = response;
      return divisionNames;
    })
    .catch((error) => {
      console.log(error);
    });
}

/**
 * Set Division Id as per the name
 *  * @param {string} locale
 */
export function setCarrier(callingClass) {
  axios(apis.getExternalCarriers(), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((result) => {
      let response = result.data;
      divisionNames = response;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getDivisionName(id) {
  if (divisionNames !== undefined && divisionNames !== null) {
    if (divisionNames[id] !== undefined) {
      if (divisionNames[id] !== '' && divisionNames[id] !== undefined) {
        return /* id+' - '+ */ divisionNames[id];
      } else return '-';
    } else {
      return '-';
    }
  } else {
    return '-';
  }
}

export { divisionNames };
