//import { AppEnv } from "./properties";
//import { TYPE_APP, TYPE_DOMAIN } from "./services/constants";

export function getHost() {
  if (window.location.hostname.toLowerCase().indexOf('salesleadsdev') >= 0
  ) {
    return `https://apidev.parker.com/dev/`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleadstest') >= 0
  ) {
    return `https://apidev.parker.com/test/`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleadsstage') >= 0
  ) {
    return `https://api.parker.com/stage/`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleads.parker') >= 0
  ) {
    return `https://api.parker.com/prod/`;
  } else return `https://apidev.parker.com/test/`;
}

export function getAPIAuthKey() {
  if (window.location.hostname.toLowerCase().indexOf('salesleadsdev') >= 0) {
    return `9a7b6552198d41fb9b000548826f59fb`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleadstest') >= 0
  ) {
    return `9a7b6552198d41fb9b000548826f59fb`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleadsstage') >= 0
  ) {
    return `86792e9e7d40460bb5486475340181be`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleads.parker') >= 0
  ) {
    return `86792e9e7d40460bb5486475340181be`;
  } else return `9a7b6552198d41fb9b000548826f59fb`;
}

/* export function getHeaderAuthKey() {
  if (
    window.location.hostname.toLowerCase().indexOf("salesleadsdev") >= 0
  ) {
    return `9a7b6552198d41fb9b000548826f59fb`;
  } else if (
    window.location.hostname.toLowerCase().indexOf("salesleadstest") >= 0
  ) {
    return `9a7b6552198d41fb9b000548826f59fb`;
  } else if (
    window.location.hostname.toLowerCase().indexOf("salesleadspreview") >= 0
  ) {
    return `e4ed15b84d2841528f9f635a9a72ebae`;
  } else if (
    window.location.hostname.toLowerCase().indexOf("salesleads") >= 0
  ) {
    return `7a3bef6c73d0488cb72758aa411c6b3c`;
  } else return `9a7b6552198d41fb9b000548826f59fb`;
} */

export function getAPIAuthKeyHeaderFooter() {
  if (window.location.hostname.toLowerCase().indexOf('salesleadsdev') >= 0) {
    return `9a7b6552198d41fb9b000548826f59fb`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleadstest') >= 0
  ) {
    return `9a7b6552198d41fb9b000548826f59fb`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleadsstage') >= 0
  ) {
    return `e91ac95d27fc4c54b29ee5084d921fd7`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleads.parker') >= 0
  ) {
    return `a060321b2e524d95957cfc67f953d458`;
  } else return `9a7b6552198d41fb9b000548826f59fb`;
}

export function getPHHost() {
  if (window.location.hostname.toLowerCase().indexOf('salesleadsdev') >= 0) {
    return `https://phdev.parker.com/`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleadstest') >= 0
  ) {
    return `https://phtest.parker.com/`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleadsstage') >= 0
  ) {
    return `https://phstage.parker.com/`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleads.parker') >= 0
  ) {
    return `https://ph.parker.com/`;
  } else return `https://phtest.parker.com/`;
}

export function getParkerHost() {
  if (window.location.hostname.toLowerCase().indexOf('salesleadsdev') >= 0) {
    return `https://dev.parker.com/`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleadstest') >= 0
  ) {
    return `https://test.parker.com/`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleadsstage') >= 0
  ) {
    return `https://preview.parker.com/`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleads.parker') >= 0
  ) {
    return `https://www.parker.com/`;
  } else return `https://test.parker.com/`;
}
 
export function getEnviorment() {
  if (window.location.hostname.toLowerCase().indexOf('salesleadsdev') >= 0) {
    return `dev`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleadstest') >= 0
  ) {
    return `test`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleadsstage') >= 0
  ) {
    return `stage`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleads.parker') >= 0
  ) {
    return `prod`;
  } else return `test`;
}
export function getSubscriptionKey() {
  if (window.location.hostname.toLowerCase().indexOf('salesleadsdev') >= 0) {
    return `9a7b6552198d41fb9b000548826f59fb`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleadstest') >= 0
  ) {
    return `9a7b6552198d41fb9b000548826f59fb`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleadsstage') >= 0
  ) {
    return `b21ca5a56a8a470bb23bb8efa5c70abf`;
  } else if (
    window.location.hostname.toLowerCase().indexOf('salesleads.parker') >= 0
  ) {
    return `b21ca5a56a8a470bb23bb8efa5c70abf`;
  } else return `9a7b6552198d41fb9b000548826f59fb`;
}
