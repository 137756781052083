// import { useRouter } from 'next/router';
// import Link from 'next/link';
// import getConfig from 'next/config';
import React  from 'react';

import * as translationUtils from '../../common-utils/translation_utils';

const Breadcrumb = ({ pageName }) => {
  var pageItemId;
  if (pageName === 'Lead Notes') {
    pageItemId = '3319';
  } else if (pageName === 'Lead Summary Page') {
    pageItemId = '3318';
  }  else if (pageName === 'Create Note') {
    pageItemId = '3293';
  }  else if (pageName === 'Note') {
    pageItemId = '3331';
  }
  else if (pageName === 'Sales Leads') {
    pageItemId = '3252';
  }
  
  //console.log('Page', pageItemId);
  const refresh = () => window.location.reload(true);
  // const getBreadcrumbName = () => {
  //   let retVal = pageName;
  //   if (pageName.includes("Lead Summary Page")) {
  //     retVal = "Sales Leads / Summary";
  //   } else if (pageName.includes("Notes")) {
  //     retVal = "Sales Leads / Notes";
  //   }
  //   return retVal;
  // };

  return (
    <>
      <div className="header-title-container">
        <h1 className="header-title" style={{ cursor: 'default' }}>
          {translationUtils.getTranslation(`${pageItemId}`, `${pageName}`)}
        </h1>
      </div>
      {/* code for Breadcrumb Links */}

      <div className="container-fluid breadcrumb-container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item" aria-current="page">
              <a href="#!" onClick={refresh} style={{ textDecoration: 'none' }}>
                {translationUtils.getTranslation(791, 'Home')}
              </a>
            </li>
            <li
              className="breadcrumb-item"
              style={{ cursor: 'default', marginTop: '-1px' }}
            >
              {/* {(pageName ==== 'Lead Summary Page' || pageName ==== 'Sales Leads' )? 
                translationUtils.getTranslation(`${pageItemId}`, `${pageName}`)
              :  translationUtils.getTranslation(`${pid}`, `${pName}`) +' / ' + translationUtils.getTranslation(`${pageItemId}`, `${pageName}`) }  */}
              {translationUtils.getTranslation(`${pageItemId}`, `${pageName}`)}
            </li>
          </ol>
        </nav>
      </div>
    </>
  );
};
export default Breadcrumb;
