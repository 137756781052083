import React, { useState } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import './notes.css';
import CreateNotes from './createNotes';
import { useEffect } from 'react';
import InnerHTML from 'dangerously-set-html-content';
import ViewNoteDetail from './viewNoteDetail';
import { useDispatch, useSelector } from 'react-redux';
import * as translationUtils from '../../../../common-utils/translation_utils';
import axios from 'axios';
import { getAPIAuthKey, getHost } from '../../../../common-utils/apiconfig';
import { getNoteById } from '../../../../common-utils/salesLeadsApi';

const Notes = ({
  setPage,
  keyNumbers,
  setOpenAction,
  leadName,
  setIsCommonLoading,
  noteType,
  distributorAccountNumber,
  setRefreshList,
  setNoteLoading,
  noteloading,
  setAlertStatus,
  setAlertMessage,
  setModalIsOpen,
  setNoteCount,
  leadSource,
}) => {
  //console.log("notes=", keyNumbers);
  const [rowData, setRowData] = useState([]);
  const [isShown, setIsShown] = useState(false);
  const [isViewNote, setIsViewNote] = useState(false);
  //const [refreshList, setRefreshList] = useState('');
  const [noteTypeValue, setNoteTypeValue] = useState('GENERAL');
  const [noteID, setNoteID] = useState();
  const [itemData, setItemData] = useState();

  const dispatch = useDispatch();

  const notesSlice = useSelector((state) => state.notesSlice);

  const handleClick = (event) => {
    // setIsShown(current => !current);
    setIsShown(true);
    setIsViewNote(false);
    changeScreen('Create Note');
    setOpenAction(false);
  };
  //  console.log("useStage value", noteTypeValue);
  const changeScreen = (name) => {
    setPage(name);
  };
  const handleChange = (event) => {
    setNoteTypeValue(event.value);
  };
  const handleViewNote = (noteId, items) => {
    axios(
      getHost() + getNoteById + `${keyNumbers}/SourceSystem/${leadSource}/Child/Note/${noteId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'ocp-apim-subscription-key': getAPIAuthKey(),
        },
      }
    )
      .then((res) => {
        if (res.status === 200) { 
          console.log("View notes==", res?.data?.response) 
          setItemData(res?.data?.response);
        }else{
          setItemData(items);
        }
      })
      .catch((err) => console.log(err));
    
    setNoteID(noteId);
   //setItemData(items);
    setIsShown(true);
    setIsViewNote(true);
    changeScreen('Note');
  };

  // useEffect(() => {
  //   setLoading(true);
  //   if (
  //     typeof keyNumbers !== 'undefined'
  //   ) {
  //     dispatch(retrieveAllNotes({ keyNumbers, noteTypeValue }));
  //   }
  // }, [keyNumbers, noteTypeValue, refreshList]);

  useEffect(() => {
    setNoteTypeValue(noteType);
  }, [noteType]);

  useEffect(() => {
    //console.log("notesSlice==", notesSlice)
    setRowData(notesSlice);
    setNoteLoading(false);
  }, [notesSlice]);

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const getFormattedDate = (newDate) => {
    let objectDate = new Date(newDate);
    let day = objectDate.getDate();
    let month = monthNames[objectDate.getMonth()];
    let year = objectDate.getFullYear();
    let hrs = objectDate.getHours();
    let mins = objectDate.getMinutes();
    let sec = objectDate.getSeconds();
    let format1 =
      month + '/' + day + '/' + year + ' ' + hrs + ':' + mins + ':' + sec;
    return format1;
  };
  return (
    <div className="notesWrapper">
      {isShown ? (
        isViewNote ? (
          <ViewNoteDetail
            keyNumbers={keyNumbers}
            setIsShown={setIsShown}
            setRefreshList={setRefreshList}
            leadName={leadName}
            noteId={noteID}
            itemData={itemData}
            setIsCommonLoading={setIsCommonLoading}
            distributorAccountNumber={distributorAccountNumber}
            setAlertStatus={setAlertStatus}
            setAlertMessage={setAlertMessage}
            setModalIsOpen={setModalIsOpen}
            setPage={setPage}
            leadSource={leadSource}
          />
        ) : (
          <CreateNotes
            keyNumbers={keyNumbers}
            setIsShown={setIsShown}
            setRefreshList={setRefreshList}
            leadName={leadName}
            setIsCommonLoading={setIsCommonLoading}
            distributorAccountNumber={distributorAccountNumber}
            setAlertStatus={setAlertStatus}
            setAlertMessage={setAlertMessage}
            setModalIsOpen={setModalIsOpen}
            setPage={setPage}
            leadSource={leadSource}
          />
        )
      ) : (
        <>
        {leadSource==='sforce'? (
       <p className='warning_msg'><i className='fa fa-info-circle'></i> <b>Attention</b> : All Notes are public and can be viewed by any user with access to this lead.</p>
    ):(null)}
          <Row>
            <Col sm={12} className="pad-0">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="lead_name lead_title">{leadName}</div>
                <Button
                  style={{ float: 'right' }}
                  className="transparent-button wd"
                  onClick={handleClick}
                >
                  {translationUtils.getTranslation(3293, 'Create Note')}
                </Button>
              </div>
            </Col>
          </Row>

          {/* <Row
            style={{ position: "relative", flexDirection: "" }}
            className="noteAction"
          >
            <Col sm={6}>
              <label className="sl-Label">Show</label>
              <CustomSelect
                onChange={(event) => handleChange(event)}
                defaultValue={noteTypeValue}
                value={noteTypeValue}
                options={[
                  { label: "All Notes", value: "Allnotes" },
                  { label: "General", value: "GENERAL" },
                  { label: "Special", value: "SPECIAL" },
                  { label: "Reference", value: "REFERENCE" },
                ]}
              />
            </Col>
          </Row> */}
          <Row className="note-card-wrapper">
            <Col sm={12} className="pad-0">
              {!noteloading ? (
                rowData?.length > 0 ? (
                  rowData &&
                  rowData?.map((item, index) => {
                    return (
                      <div
                        className="note-card"
                        key={index}
                        onClick={() => handleViewNote(item.noteId, item)}
                      >
                        <div>
                          <b>{item?.noteTitle}</b>
                        </div>
                        <div>
                          {translationUtils.getTranslation(3297, 'Updated') +
                            ': ' +
                            getFormattedDate(item?.creationDate)}
                        </div>
                        {/* <div>
                          <b>{item?.createdBy}</b> Type: {item?.noteTypeCode}
                        </div> */}
                        <div>
                          <br />
                          {item?.noteTxt === '' ? (
                            ''
                          ) : (
                            <>
                              <InnerHTML
                                html={item?.noteTxt}
                                key={Math.random()}
                              />
                            </>
                            // <InnerHTML
                            //   html={decodeURIComponent(
                            //     escape(window.atob(item?.noteTxt))
                            //   )}
                            // />
                          )}
                        </div>
                        <div></div>
                      </div>
                    );
                  })
                ) : (
                  <p>
                    {translationUtils.getTranslation(
                      3292,
                      'No data to display'
                    )}
                    .{' '}
                  </p>
                )
              ) : (
                <div id="preloader" className="order-status-hifi">
                  <div className="loading-overlay">
                    &nbsp; <p></p>
                  </div>
                </div>
                // <p>
                //   <img
                //     src="../assets/img/parker-search-loading.gif"
                //     alt="Loading..."
                //   />
                // </p>
              )}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
export default Notes;
