import { createSlice } from "@reduxjs/toolkit";
import DefaultInitialState from "./default_state";
import { createNote, noteType, noteTitle, noteTxt } from "./default_actions";
import {
  createNoteHandler,
  noteTypeHandler,
  noteTitleHandler,
  noteTxtHandler,
} from "./default_actions_handlers";

export default createSlice({
  name: "default",
  initialState: DefaultInitialState,
  extraReducers: {
    [createNote]: createNoteHandler,
    [noteType]: noteTypeHandler,
    [noteTitle]: noteTitleHandler,
    [noteTxt]: noteTxtHandler,
  },
});
