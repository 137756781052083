import React from 'react';
import { SecureRoute, Security, LoginCallback, AuthService } from '@okta/okta-react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import config from './config';
import Wrapper from './container/wrapper/Wrapper';

const App = () => {
  const authService = new AuthService({
    ...config.oidc,
    isAuthenticated: async () => {
      console.log("authService", authService)
        let isUserAuthenticated = false;
        const existsData = await authService._oktaAuth.session.exists();
        console.log('--- existsData', existsData)
        await authService._oktaAuth.session.exists()
            .then(async (exists) => {
                console.log('-- exists', exists)
                if (exists) {
                    const idToken = await authService.getTokenManager().get('idToken');
                    const accessToken = await authService.getTokenManager().get('accessToken');
                    isUserAuthenticated = !!(idToken && accessToken);
                    console.log('-- idToken (If exists=true)', idToken)
                    console.log('-- accessToken (If exists=true)', accessToken)
                    console.log('-- isUserAuthenticated (If exists=true)', isUserAuthenticated)
                }
            });
        if (!isUserAuthenticated) {
            console.log('-- !isUserAuthenticated', isUserAuthenticated)
            console.log('-- authService (If !isUserAuthenticated)', authService)
            await authService._oktaAuth.token.getWithoutPrompt({ responseType: ['token', 'id_token'] })
                .then(function (res) {
                    authService._oktaAuth.tokenManager.add('idToken', res.tokens.idToken);
                    authService._oktaAuth.tokenManager.add('accessToken', res.tokens.accessToken);
                    isUserAuthenticated = true;
                })
                .catch(function (err) {
                    console.log(err);
                });
        }
        return isUserAuthenticated;
    }
});
console.log('-- authService', authService)

  let defaultLang = '';

  return (
    <Router>
    <Security authService={authService}>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to={`/home/${defaultLang}`} />;
          }}
        />
        <SecureRoute path="/home" component={Wrapper} />
        <Route path="/implicit/callback" component={LoginCallback} />
      </Switch>
    </Security>
    </Router>
  );
};

export default App;
