import React, { useState } from 'react';
import { Row, Modal } from 'react-bootstrap';
import './retireRejectLead.css';
import cross from './cross.svg';
import axios from 'axios';
import { getAPIAuthKey, getHost } from '../../common-utils/apiconfig';
import CustomSelect from '../components/CustomSelect';
import * as translationUtils from '../../common-utils/translation_utils'; 
import { rejectLeadApi, retireLeadApi } from '../../common-utils/salesLeadsApi';

const RetireRejectLead = (props) => {
  const [retireReason, setRetireReason] = useState(null);
  const [rejectReason, setRejectReason] = useState(null);
  const [comments, setComments] = useState('');
  let retireReasonList =''
 
  const onChangeComments = (event) => {
    setComments(event.target.value);
  };

  const onChangeRetireReason = (selectedOption) => {
    setRetireReason(selectedOption.value);
  };

  const onChangeRejectReason = (selectedOption) => {
    setRejectReason(selectedOption.value);
  };

  const reset = () => {
    setRetireReason(null);
    setRejectReason(null);
    setComments('');
  };

  const onClickSubmit = () => {
    if (props.isRetireLead) {
      submitRetireLead();
    } else {
      submitRejectLead();
    }
  };

  const submitRetireLead = async () => {
    //console.log('retire lead reason', retireReason);
    //console.log('retire lead comment', comments);
    //console.log('retire lead number', props.leadKeyNumber);

    props.setIsLoading(true);
    // let statusCode= {retireReason=='Won'? 'Qualified': 'RETIRED'}
    if(props.leadRank!=''){
    const body = {
      statusCode: props.leadSource==='sforce'? (retireReason==='Won'? 'Qualified': 'Rejected') : 'RETIRED',
      rank:props.leadRank,
      retireComment: comments,
      retireReasonCode: retireReason,
    };

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'ocp-apim-subscription-key': getAPIAuthKey(),
      },
    };

    await axios
      .patch(
        getHost() + retireLeadApi+`${props.leadKeyNumber}/SourceSystem/${props.leadSource}`,
        body,
        headers
      )
      .then((response) => {
        //console.log('retire lead', response);
        if (response?.data?.status === 1) {
           //alert('Retire lead submitted successfully!');
          
          props.setModalIsOpen(true);
          props.setAlertStatus('Success');
          props.setAlertMessage('Retire lead submitted successfully!!');
          reset();
          props.handleCloseModal();
          props.handleClose();
          props.setIsRetireRejectSubmited('Retire-' + props.leadKeyNumber);
          props.setIsLoading(false);
          
        } else {
          //alert('Retire Lead not submitted!');
          
          props.setModalIsOpen(true);
          props.setAlertStatus('Failed');
          props.setAlertMessage('Retire Lead not submitted!!');
          reset();
          props.handleCloseModal();
          props.handleClose();
          props.setIsLoading(false);
        }
      })
      .catch((error) => {
         props.setModalIsOpen(true);
          props.setAlertStatus('Failed');
          props.setAlertMessage('Something went wrong!!');
        props.setIsLoading(false);
      });
    }else{
      props.setModalIsOpen(true);
          props.setAlertStatus('Failed');
          props.setAlertMessage('You must set a Rank to this lead, first!!');
        props.setIsLoading(false);
    }
  };
  

  const submitRejectLead = async () => {
    // console.log('Reject lead reason', rejectReason);
    // console.log('Reject lead comment', comments);
    // console.log('Reject lead number', props.leadKeyNumber);
    // if (props.leadRank !== '') { }else{}
    props.setIsLoading(true);
    const body = {
      leadNumber: props.leadKeyNumber,
      reason: rejectReason,
      comments: comments,
      rank: props.leadRank,
      rejectingEmailId: sessionStorage.getItem('oktaUserEmail'),
      rejectingUserName: sessionStorage.getItem('oktaUserName'),
    };

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'ocp-apim-subscription-key': getAPIAuthKey(),
      },
    };

    await axios
      .post(
        getHost() + rejectLeadApi+`/${props.leadKeyNumber}/SourceSystem/${props.leadSource}`,
        body,
        headers
      )
      .then((response) => {
        //console.log('Reject lead', response);
        if (response?.data?.status === 1) {
          // alert('Reject lead submitted successfully!'); 
           props.setModalIsOpen(true);
           props.setAlertStatus('Success');
           props.setAlertMessage('Reject lead submitted successfully!');
          reset();
          props.handleCloseModal();
          props.handleClose();
          props.setIsRetireRejectSubmited('Reject-' + props.leadKeyNumber);
          props.setIsLoading(false);
        } else {
          // alert('Reject Lead not submitted!');
          reset();
          props.handleCloseModal();
          props.handleClose();
          props.setIsLoading(false);
          
          props.setModalIsOpen(true);
          props.setAlertStatus('Failed');
          props.setAlertMessage('Reject Lead not submitted!!');
        }
      })
      .catch((error) => {
        props.setModalIsOpen(true);
          props.setAlertStatus('Failed');
          props.setAlertMessage('Something went wrong!!');
        props.setIsLoading(false);
      });
  };
  
  if(props.leadSource==='oec'){
  retireReasonList=[
    { label: 'Duplicate Lead', value: 'DUPLICATE_LEAD' },
    { label: 'Failed to Reach Contact', value: 'FAILED_TO_REACH_CONTACT'},
    { label: 'Financial Risk', value: 'FINANCIAL_RISK' },
    { label: 'Insufficient/Bad Info', value: 'INSUFFICIENT_BAD_INFO'},
    { label: 'Lost', value: 'LOST' },
    { label: 'No Purchase Interest', value: 'NOT_INTERESTED' },
    { label: 'Won', value: 'WON' },
  ];
}else{
  retireReasonList=[
    {
      "label": "Service Request",
      "value": "Service Request"
    },
    {
      "label": "Went with Competitor",
      "value": "Went with Competitor"
    },
    {
      "label": "Price",
      "value": "Price"
    },
    {
      "label": "Timing",
      "value": "Timing"
    },
    {
      "label": "Not a good fit",
      "value": "Not a good fit"
    },
    {
      "label": "Not interested",
      "value": "Not interested"
    },
    {
      "label": "Duplicate lead",
      "value": "Duplicate lead"
    },
    {
      "label": "Wrong contact information",
      "value": "Wrong contact information"
    },
    {
      "label": "Won",
      "value": "Won"
    }
  ]

}
  return (
    <> 
    <Modal
      show={props.show}
      onHide={props.handleCloseModal}
      dialogClassName="modal_popup"
    >
      <div className="cross"> 
        <img
          src={cross}
          className="cross_img"
          onClick={props.handleCloseModal}
          alt='close'
        />
      </div>
      <div className="model_heading">
        {props.isRetireLead ? translationUtils.getTranslation(3300, 'Retire Lead') : translationUtils.getTranslation(3303, 'Reject Lead')} 
        {/* {props.leadSource}-{props.leadRank} */}
      </div>
      <hr className="hr_top" />
      <div className="instruction">{translationUtils.getTranslation(1453, 'Please enter your information below')}.</div>
      <Row sm={2}>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="field_label label1">
            {props.isRetireLead ? translationUtils.getTranslation(3301, 'Retire Reason') : translationUtils.getTranslation(3304, 'Reject Reason')} 
          </div>
          {props.isRetireLead ? (
            <CustomSelect
              className="field_ddl"
              id="ddlRetireReason"
              defaultValue={retireReason}
              value={retireReason}
              onChange={onChangeRetireReason}
              options={retireReasonList}
              placeholder={translationUtils.getTranslation(1207, 'Select an option')}
            />
          ) : (
            <CustomSelect
              className="field_ddl"
              id="ddlRejectReason"
              defaultValue={rejectReason}
              value={rejectReason}
              onChange={onChangeRejectReason}
              placeholder={translationUtils.getTranslation(1207, 'Select an option')}
              options={[
                {
                  label: 'Incorrect Product Assignment',
                  value: 'INCORRECT_PRODUCT_ASSIGNMENT',
                },
                {
                  label: 'Incorrect Territory Assignment',
                  value: 'INCORRECT_TERRITORY_ASSIGNMENT',
                },
              ]}
            />
          )}
        </div>
      </Row>
      <Row sm={2}>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="field_label label2">
            {translationUtils.getTranslation(751, 'Comments')}
          </div>
          <textarea
            id="txtComments"
            maxLength="250"
            placeholder={translationUtils.getTranslation(3302, 'Enter Comments here')}
            className="textarea_field"
            value={comments}
            onChange={(event) => onChangeComments(event)}
          />
        </div>
      </Row>
      <hr className="hr_bottom" />
      <div className="button_container">
        <button
          className="transparent-button cancel_lead retireLeadButton"
          onClick={() => {
            props.handleCloseModal();
            reset();
          }}
        >
          {translationUtils.getTranslation(404, 'Cancel')}
        </button>
        <button
          className="transparent-button save_lead retireLeadButton"
          onClick={onClickSubmit}
        >
          {translationUtils.getTranslation(57, 'Submit')}
        </button>
      </div>
    </Modal>
    </>
  );
};
export default RetireRejectLead;
