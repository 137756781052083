import { getHost, getPHHost } from "./apiconfig";
/*
/*
 *Defining All the API Urls that are being used. 
*/

/**
 * Get Auth URL
 * @return {string} url
 */
export function getAuthUrl() {
  if (window.location.hostname.toLowerCase().indexOf("cor089") < 0)
    return (
      "https://" +
      window.location.hostname.replace(".", "-api.") +
      "/order-status/getToken"
    );
  else return "https://orderstatusdev-api.us.parker.corp/order-status/getToken";
}

/**
 * Get Varification URL
 * @return {string} url
 */
export function getVarificationUrl() {
  return `${window.allData.userVerificationUrl}api/v1/sessions/me`;
}

/** Get Url to get the notification data and count */
/* export function getNotificationUrl(contractId) {
  if (window.location.hostname.toLowerCase().indexOf("cor089") < 0) {
    return `/v3/api/application/MyParker/getNotificationData`;
  } else
    return "http://cor089a029:8080/api/application/MyParker/getNotificationData";
} */

/** Get Url for find the auth Id associated with that customer */
/* export function getCustAuth(contractId) {
  if (window.location.hostname.toLowerCase().indexOf("cor089") < 0) {
    return `/v3/SolrApi/solr/DistributorService/auth?ContractId=${contractId}`;
  } else
    return "http://contentsearch.us.parker.corp/SolrApi/solr/DistributorService/auth?ContractId=4000000000000025006";
} */
/*
Get URL of validate expire time API
*/
export function getExpireTime(sessionId) {
  if (
    window.location.hostname.toLowerCase().indexOf("cor089") < 0 &&
    window.location.hostname.toLowerCase().indexOf("localhost") < 0
  )
    return "/order-status-lite-api/api/v1/sessions/" + sessionId;
  else
    return (
      "http://orderstatusdev-api.us.parker.corp/order-status-lite-api/api/v1/sessions/" +
      sessionId
    );
}

/**
 * Get Order Url based on type
 * @param {string} type
 * @param {any} filter
 * @param {string} filterName
 * @return {string} url
 */
export function getOrderUrl(type, filter = JSON.parse("{}"), startIndex = 1) {
  var url = "";
  if (
    window.location.hostname.toLowerCase().indexOf("cor089") < 0 &&
    window.location.hostname.toLowerCase().indexOf("localhost") < 0
  ) {
    if (filter["sellerCode"] !== undefined)
      url =
        getHost() +
        "orderstatus/api/v2/administrators/" +
        type +
        "/orders?customer=" +
        filter["sellerCode"];

    if (filter["keyword"] !== undefined && filter["keyword"][0] !== undefined) {
      url =
        url +
        "&searchbyterm=" +
        filter["keyword"][0] +
        "&searchkeyword=" +
        filter["keyword"][1];
    }
    if (filter["customerPO"] !== undefined) {
      url = url + "&customerPO=" + filter["customerPO"][1];
    }

    if (filter["daterange"] !== undefined) {
      let fromDate = filter["daterange"][0].split("-");
      let toDate = filter["daterange"][1].split("-");
      fromDate = fromDate[2] + fromDate[0] + fromDate[1];
      toDate = toDate[2] + toDate[0] + toDate[1];

      url = url + "&fromdate=" + fromDate + "&enddate=" + toDate;
    }
    if (filter["orderstatus"] !== undefined && filter["orderstatus"] !== "") {
      url = url + "&orderstatus=" + filter["orderstatus"];
    }
    if (filter["orderplacedwithin"] !== undefined) {
      url = url + "&duration=" + filter["orderplacedwithin"];
    }
    if (filter["cusName"] !== undefined && filter["cusName"] !== "") {
      url = url + "&custName=" + filter["cusName"];
    }
  } else {
    if (filter["sellerCode"] !== undefined)
      url =
        getHost() +
        "orderstatus/api/v2/administrators/" +
        type +
        "/orders?customer=" +
        filter["sellerCode"];

    if (filter["keyword"] !== undefined && filter["keyword"][0] !== undefined) {
      url =
        url +
        "&searchbyterm=" +
        filter["keyword"][0] +
        "&searchkeyword=" +
        filter["keyword"][1];
    }
    if (filter["customerPO"] !== undefined) {
      url = url + "&customerPO=" + filter["customerPO"][1];
    }

    if (filter["daterange"] !== undefined) {
      let fromDate = filter["daterange"][0].split("-");
      let toDate = filter["daterange"][1].split("-");
      fromDate = fromDate[2] + fromDate[0] + fromDate[1];
      toDate = toDate[2] + toDate[0] + toDate[1];

      url = url + "&fromdate=" + fromDate + "&enddate=" + toDate;
    }
    if (
      filter["orderstatus"] !== undefined &&
      filter["orderstatus"] !== "" &&
      filter["orderstatus"] !== ""
    ) {
      url = url + "&orderstatus=" + filter["orderstatus"];
    }
    if (filter["orderplacedwithin"] !== undefined) {
      url = url + "&duration=" + filter["orderplacedwithin"];
    }
    if (filter["cusName"] !== undefined && filter["cusName"] !== "") {
      url = url + "&custName=" + filter["cusName"];
    }
  }

  if (type !== "shipment" && filter["cust"]) {
    if (filter["sellerCode"] === undefined) {
      return (url = "");
    } else {
      if (
        window.location.hostname.toLowerCase().indexOf("cor089") < 0 &&
        window.location.hostname.toLowerCase().indexOf("localhost") < 0
      ) {
        if (filter["pageToken"] !== undefined) {
          url =
            getHost() +
            "orderstatus/api/v2/administrators/" +
            type +
            "/getorderbycustomer?customer=" +
            filter["sellerCode"] +
            "&pageNo=" +
            filter["pageno"] +
            "&pageSize=" +
            filter["pagesize"] +
            "&pagesettoken=" +
            filter["pageToken"];

          if (
            window.searchType !== undefined &&
            window.searchType !== "" &&
            window.searchKeyword !== undefined &&
            window.searchKeyword !== ""
          ) {
            url = url + "&searchbyterm=OrderNo"; // + window.searchType;
          }
          if (
            window.searchType !== undefined &&
            window.searchType !== "" &&
            window.searchKeyword !== undefined &&
            window.searchKeyword !== ""
          ) {
            url = url + "&searchkeyword=" + window.searchKeyword;
          }
          if (
            filter["orderstatus"] !== undefined &&
            filter["orderstatus"] !== ""
          ) {
            url = url + "&orderstatus=" + filter["orderstatus"];
          }
          if (window.duration !== undefined && window.duration !== "") {
            url = url + "&duration=" + window.duration;
          }
          if (window.fDate !== undefined && window.fDate !== "") {
            url = url + "&fromdate=" + window.fDate;
          }
          if (window.tDate !== undefined && window.tDate !== "") {
            url = url + "&enddate=" + window.tDate;
          }
        } else {
          url =
            getHost() +
            "orderstatus/api/v2/administrators/" +
            type +
            "/getorderbycustomer?customer=" +
            filter["sellerCode"] +
            "&pageNo=" +
            filter["pageno"] +
            "&pageSize=" +
            filter["pagesize"];

          if (
            filter["keyword"] !== undefined &&
            filter["keyword"][0] !== undefined
          ) {
            url =
              url +
              "&searchbyterm=" +
              filter["keyword"][0] +
              "&searchkeyword=" +
              filter["keyword"][1];
          }
          if (type === "open" && window.orderStatus === "Shipped") {
          } else if (
            window.orderStatus !== undefined &&
            window.orderStatus !== ""
          ) {
            url = url + "&orderstatus=" + window.orderStatus;
          }
          if (window.duration !== undefined && window.duration !== "") {
            url = url + "&duration=" + window.duration;
          }
          if (window.fDate !== undefined && window.fDate !== "") {
            url = url + "&fromdate=" + window.fDate;
          }
          if (window.tDate !== undefined && window.tDate !== "") {
            url = url + "&enddate=" + window.tDate;
          }
        }
      } else {
        if (filter["pageToken"] !== undefined) {
          url =
            getHost() +
            "orderstatus/api/v2/administrators/" +
            type +
            "/getorderbycustomer?customer=" +
            filter["sellerCode"] +
            "&pageNo=" +
            filter["pageno"] +
            "&pageSize=" +
            filter["pagesize"] +
            "&pagesettoken=" +
            filter["pageToken"];

          if (
            window.searchType !== undefined &&
            window.searchType !== "" &&
            window.searchKeyword !== undefined &&
            window.searchKeyword !== ""
          ) {
            url = url + "&searchbyterm=OrderNo";
          }
          if (
            window.searchType !== undefined &&
            window.searchType !== "" &&
            window.searchKeyword !== undefined &&
            window.searchKeyword !== ""
          ) {
            url = url + "&searchkeyword=" + window.searchKeyword;
          }

          if (window.orderStatus !== undefined && window.orderStatus !== "") {
            url = url + "&orderstatus=" + window.orderStatus;
          }
          if (window.duration !== undefined && window.duration !== "") {
            url = url + "&duration=" + window.duration;
          }
          if (window.fDate !== undefined && window.fDate !== "") {
            url = url + "&fromdate=" + window.fDate;
          }
          if (window.tDate !== undefined && window.tDate !== "") {
            url = url + "&enddate=" + window.tDate;
          }
        } else {
          url =
            getHost() +
            "orderstatus/api/v2/administrators/" +
            type +
            "/getorderbycustomer?customer=" +
            filter["sellerCode"] +
            "&pageNo=" +
            filter["pageno"] +
            "&pageSize=" +
            filter["pagesize"];

          if (
            filter["keyword"] !== undefined &&
            filter["keyword"][0] !== undefined
          ) {
            url =
              url +
              "&searchbyterm=" +
              filter["keyword"][0] +
              "&searchkeyword=" +
              filter["keyword"][1];
          }
          if (type === "open" && window.orderStatus === "Shipped") {
          } else if (
            window.orderStatus !== undefined &&
            window.orderStatus !== ""
          ) {
            url = url + "&orderstatus=" + window.orderStatus;
          }
          if (window.duration !== undefined && window.duration !== "") {
            url = url + "&duration=" + window.duration;
          }
          if (window.fDate !== undefined && window.fDate !== "") {
            url = url + "&fromdate=" + window.fDate;
          }
          if (window.tDate !== undefined && window.tDate !== "") {
            url = url + "&enddate=" + window.tDate;
          }
        }
      }
    }
  }
  if (
    type === "shipment" &&
    filter["shiptype"] !== undefined &&
    filter["sellerCode"] !== undefined
  ) {
    if (
      window.location.hostname.toLowerCase().indexOf("cor089") < 0 &&
      window.location.hostname.toLowerCase().indexOf("localhost") < 0
    ) {
      url =
        getHost() +
        "orderstatus/api/v2/administrators/all/orders/shipmentdiagest?customer=" +
        filter["sellerCode"] +
        "&duration=" +
        filter["shiptype"];
      return url + "&Start=" + startIndex;
    } else {
      url =
        getHost() +
        "orderstatus/api/v2/administrators/all/orders/shipmentdiagest?customer=" +
        filter["sellerCode"] +
        "&duration=" +
        filter["shiptype"];
      return url + "&Start=" + startIndex;
    }
  } else if (type === "shipment") {
    if (
      window.location.hostname.toLowerCase().indexOf("cor089") < 0 &&
      window.location.hostname.toLowerCase().indexOf("localhost") < 0
    ) {
      url =
        getHost() +
        "orderstatus/api/v2/administrators/all/orders/shipmentdiagest?customer=" +
        filter["sellerCode"] +
        "&duration=yesterday";
      return url + "&Start=" + startIndex;
    } else {
      url =
        getHost() +
        "orderstatus/api/v2/administrators/all/orders/shipmentdiagest?customer=" +
        filter["sellerCode"] +
        "&duration=yesterday";
      return url + "&Start=" + startIndex;
    }
  }

  /* if (filter["keyword"] !== undefined && filter["keyword"][0] !== undefined) {
    url =
      url +
      "&searchbyterm=" +
      filter["keyword"][0] +
      "&searchkeyword=" +
      filter["keyword"][1];
  }
  if (filter["customerPO"] !== undefined) {
    url = url + "&customerPO=" + filter["customerPO"][1];
  }

  if (filter["daterange"] !== undefined) {
    let fromDate = filter["daterange"][0].split("-");
    let toDate = filter["daterange"][1].split("-");
    fromDate = fromDate[2] + fromDate[0] + fromDate[1];
    toDate = toDate[2] + toDate[0] + toDate[1];

    url = url + "&fromdate=" + fromDate + "&enddate=" + toDate;
  }
  if (filter["orderstatus"] !== undefined) {
    url = url + "&orderstatus=" + filter["orderstatus"];
  }
  if (filter["orderplacedwithin"] !== undefined) {
    url = url + "&duration=" + filter["orderplacedwithin"];
  }
  if (filter["cusName"] !== undefined && filter["cusName"] !== "") {
    url = url + "&custName=" + filter["cusName"];
  } */
  //return url+'&Start='+startIndex+'&sessionid='+window.sessionStorage.getItem('oktaSessionId');
  return url;
}

/**
 * Get Translation Url based on Ids and Locale
 * @param {string} ids //Comman Seperated Ids
 * @param {string} locale
 * @return {string} url
 */
export function getTranslationUrl() {
  if (
    window.location.hostname.toLowerCase().indexOf("cor089") < 0 &&
    window.location.hostname.toLowerCase().indexOf("localhost") < 0
  )
    return getHost() + "/TranslationRestAPI/Translation/getTranslations";
  else
    return "https://apidev.parker.com/test/TranslationRestAPI/Translation/getTranslations";
}

/* To get all the Division url from Divion name */
export function getDivisionUrl() {
  return getHost() + "orderstatus/api/v1/administrators/getalldivisionname";
}

/* To get all the Division url from Divion name */
export function getExternalCarriers() {
  if (
    window.location.hostname.toLowerCase().indexOf("cor089") < 0 &&
    window.location.hostname.toLowerCase().indexOf("localhost") < 0
  )
    return "/v3/order-status-lite-api/getallcarrier";
  else return "https://localhost:9081/getallcarrier";
}
/**
 * Get Generate Report URL
 * @param {string} duration
 * @return {string} url
 */
export function getGenerateReportUrl(filter = JSON.parse("{}"), type, cust) {
  var url = "";
  if (
    window.location.hostname.toLowerCase().indexOf("cor089") < 0 &&
    window.location.hostname.toLowerCase().indexOf("localhost") < 0
  ) {
    if (type !== "shipment")
      url =
        getHost() +
        "orderstatus/api/v3/administrators/" +
        filter["orderType"] +
        "/orders/generatereport?useremail=" +
        window.sessionStorage.oktaLogin;
    else
      url =
        getHost() +
        "orderstatus/api/v2/administrators/all/orders/shipmentdigestreport?useremail=" +
        window.sessionStorage.oktaLogin;
  } else {
    if (type !== "shipment")
      url =
        getHost() +
        "orderstatus/api/v3/administrators/" +
        filter["orderType"] +
        "/orders/generatereport?useremail=" +
        window.sessionStorage.oktaLogin;
    else
      url =
        getHost() +
        "orderstatus/api/v2/administrators/all/orders/shipmentdigestreport?useremail=" +
        window.sessionStorage.oktaLogin;
  }
  if (cust !== null) {
    url = url + "&customer=" + cust;
  }
  if (filter["keyword"] !== undefined) {
    url =
      url +
      "&searchbyterm=" +
      filter["keyword"][0] +
      "&searchkeyword=" +
      filter["keyword"][1];
  }
  if (filter["daterange"] !== undefined) {
    let fromDate = filter["daterange"][0].split("-");
    let toDate = filter["daterange"][1].split("-");
    fromDate = fromDate[2] + fromDate[0] + fromDate[1];
    toDate = toDate[2] + toDate[0] + toDate[1];

    url = url + "&fromdate=" + fromDate + "&enddate=" + toDate;
  }
  if (filter["orderstatus"] !== undefined) {
    url = url + "&orderstatus=" + filter["orderstatus"];
  }
  if (filter["orderplacedwithin"] !== undefined) {
    url = url + "&duration=" + filter["orderplacedwithin"];
  }
  return url;
}

export function getHeaderFooterUrl(lang) {
  if (
    window.location.hostname.toLowerCase().indexOf("cor089") < 0 &&
    window.location.hostname.toLowerCase().indexOf("localhost") < 0
  )
    return (
      getPHHost() +
      "wcs/resources/store/20951/headerfooter/with/orglist/v1?langCode=" +
      lang +
      "&logonId=" +
      window.sessionStorage.getItem("oktaLogin").toLowerCase()
    );
  else
    return (
      getPHHost() +
      "wcs/resources/store/20951/headerfooter/with/orglist/v1?langCode=" +
      lang +
      "&logonId=reji.menon@parker.com"
    );
}

/* export function getHeaderCatagoriesUrl(lang) {
  if (
    window.location.hostname.toLowerCase().indexOf("cor089") < 0 &&
    window.location.hostname.toLowerCase().indexOf("localhost") < 0
  )
    return (
      getPHHost() +
      "wcs/resources/store/20951/headerfooter/categorydata?langCode=" +
      lang +
      "&catalogId=33151"
    );
  else
    return (
      getPHHost() +
      "wcs/resources/store/20951/headerfooter/categorydata?langCode=" +
      lang +
      "&catalogId=33151"
    );
} */
